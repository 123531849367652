@import 'src/assets/scss/magic';
.intro-holder {
    border-radius: rem(36);
    background: #1676de;
    display: flex;
    align-items: center;
    justify-content: center;
    position: relative;
    z-index: 0;
    overflow: hidden;
    &.suffix {
        background: #1a4263;
    }
    &.l-landing {
        margin: 0 0 rem(-$gap * 3);
        padding: 0 0 rem($gap + (15));
        @include mobile {
            border-radius: rem(24);
            width: calc(100% + var(--guide-padding));
            margin: rem(-10) calc(-1 * (var(--guide-padding) / 2)) rem(-100);
            padding: 0 var(--guide-padding);
        }
    }
    &.l-help {
        justify-content: flex-start;
        padding: rem($gap * 3) rem($gap) 0 rem($gap * 4);
        background-image: url('./media/help.jpg');
        @include mobile {
            height: rem(360);
            align-items: flex-end;
            border-radius: rem(16);
            width: calc(100% + var(--guide-padding));
            margin: rem(-10) calc(-1 * (var(--guide-padding) / 2)) 0;
            padding: var(--guide-padding);
        }
        @include retina {
            background-image: url('./media/help@2x.jpg');
        }
    }
}
.landing-splash-holder {
    width: 100%;
    height: 100%;
    object-fit: cover;
    @include retina {
        opacity: 0;
        transform: scale(2) rotate(4deg);
        &.animate {
            animation: landingSplashReveal 1s forwards;
            .white-label & {
                transform: scale(1.1);
            }
            @keyframes landingSplashReveal {
                to {
                    opacity: 1;
                    transform: scale(1) rotate(0);
                }
            }
        }
    }
}
.intro-search-holder {
    width: 100%;
    max-width: rem(420);
    display: grid;
    grid-row-gap: rem(20);
    grid-template-columns: 100%;
    > li {
        &:first-child {
            color: white;
            font: 600 f(26) $f;
        }
        &:last-child {
            border-radius: rem(100);
            background: c(b);
            position: relative;
            &:after {
                content: '';
                display: block;
                position: absolute;
                top: 0;
                left: 0;
                width: 100%;
                height: 100%;
                z-index: 2;
                border: rem(1) solid c(b);
                border-radius: inherit;
                pointer-events: none;
            }
        }
    }
}
.landing-intro-content-holder {
    position: relative;
    z-index: 1;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    text-align: center;
    padding: rem(80) 0;
    color: white;
    @include mobile {
        padding: rem(100) 0 rem(140);
    }
    > * {
        &:not(.intro-divider):not(.landing-intro-mark) {
            opacity: 0;
            animation: fadeIn 400ms forwards;
            @for $i from 1 to 5 {
                &:nth-child(#{$i}) {
                    animation-delay: #{300 + 150 * $i}ms;
                }
            }
        }
        + * {
            margin: rem(12) 0 0;
            &:nth-child(2), &:last-child {
                margin: rem(30) 0 0;
            }
        }
    }
    .landing-intro-mark {
        width: rem(170);
        height: rem(170);
        @include mobile {
            width: rem(120);
            height: rem(120);
        }
        path {
            stroke-dashoffset: 930.897705078125px;
            stroke-dasharray: 930.897705078125px;
            transition: stroke-dashoffset 1s cubic-bezier(0.47, 0, 0.745, 0.715) 0s;
            transition-delay: 500ms;
            @include mobile {
                stroke-width: rem(2);
            }
        }
        &.active {
            path {
                stroke-dashoffset: 0;
            }
        }
    }
    h1, h2 {
        text-wrap: balance;
    }
    h1 {
        font: 900 rem(38) / 1 $f;
        text-transform: uppercase;
        position: relative;
        padding-bottom: rem(13);
        @include mobile {
            font-size: rem(28);
            line-height: 1.2;
            min-height: rem(67);
        }
        &:after {
            content: '';
            position: absolute;
            bottom: 0;
            left: 0;
            right: 0;
            height: rem(1);
            transform: scaleX(0);
            background: linear-gradient(90deg, #8BFB38 0%, #D182FF 25.5%, #e4b152 61%, #FF39D4 100%);
            animation: introDividerReveal 800ms forwards 1300ms;
            @include mobile {
                left: 14%;
                right: 14%;
                background: linear-gradient(90deg, #8BFB38 0%, #D182FF 50%, #e4b152 100%);
            }
            @keyframes introDividerReveal {
                to {
                    transform: scaleX(1);
                }
            }
        }
    }
    h2 {
        width: 100%;
        max-width: rem(665);
        font: rem(18) / 150% $f;
        opacity: 0.9;
        @include mobile {
            font-size: rem(16);
            line-height: 1.4;
            min-height: rem(67);
        }
    }
}
.landing-intro-search-holder {
    min-height: rem(60);
    width: 100%;
}
