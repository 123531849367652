@import 'src/assets/scss/magic';
.input-holder {
    --additional-padding: 0px;
    --border-color: #{sc(b, 0.08)};
    width: 100%;
    transition: 400ms opacity;
    @include getSizes;
    @include hover {
        --border-color: #{sc(b, 0.15)};
    }
    &.error {
        --border-color: #{c(error)};
    }
    &.disabled {
        opacity: 0.5;
        pointer-events: none;
    }
    &:focus-within, &.popover-opened {
        --border-color: #{c(brand)};
    }
    &.cr-full {
        --border-radius: var(--height);
        --additional-padding: #{rem(4)};
    }
    &.centered {
        --additional-padding: #{rem(-10)};
        .input-element {
            justify-content: center;
            text-align: center;
        }
    }
    &.read-only {
        --border-color: transparent;
        .input-element {
            background: sc(b, 0.03);
        }
    }
}
.input-label {
    width: 100%;
    margin: 0 0 rem(6);
    font: f(12) $f;
    color: sc(b, 0.48);
    @include mobile {
        color: sc(b, 0.6);
    }
}
.input-element-holder {
    position: relative;
}
.input-element {
    display: flex;
    align-items: center;
    justify-content: flex-start;
    text-align: left;
    width: 100%;
    position: relative;
    height: var(--height);
    padding: 0 calc(var(--padding) + var(--additional-padding));
    font-size: var(--font-size);
    border-radius: var(--border-radius);
    color: sc(b);
    font-family: $f;
    &:not(.skeleton-blink) {
        transition: 400ms border-color;
        border: rem(1) solid var(--border-color);
    }
    @mixin sharedPlaceholder {
        font-size: var(--extra-font-size, var(--font-size));
        color: sc(b, 0.6);
        font-family: $f;
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
    }
    &:not(:focus) {
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
    }
    .placeholder & {
        @include sharedPlaceholder;
    }
    @include placeholder {
        @include sharedPlaceholder;
    }
    &:-webkit-autofill {
        -webkit-text-fill-color: sc(b);
        -webkit-background-clip: text;
        &::first-line {
            font-size: var(--font-size);
            font-family: $f;
        }
    }
    &[type="time"] {
        padding-right: 0;
    }
}
.input-actions {
    position: absolute;
    width: var(--height);
    height: var(--height);
    display: flex;
    justify-content: center;
    align-items: center;
    top: 0;
    color: sc(b, 0.3);
    &.input-right-actions {
        right: var(--additional-padding);
        ~ .input-element {
            padding-right: calc(var(--height) + var(--additional-padding));
        }
    }
    &.input-left-actions {
        left: var(--additional-padding);
        ~ .input-element {
            padding-left: calc(var(--height) + var(--additional-padding));
        }
    }
    .icon {
        position: relative;
        z-index: 2;
        transition: 300ms color;
        @include hover {
            color: sc(b, 0.48);
        }
    }
    .dropdown-chevron {
        &.icon-a-loader {
            color: sc(b, 0.8);
        }
        &:before {
            display: block;
            transition: 400ms transform;
            .popover-opened & {
                transform: rotate(-180deg);
            }
        }
    }
    .input-action {
        position: relative;
        z-index: 3;
    }
}
