@import "src/assets/scss/magic";
.lazy-image-holder {
    position: relative;
    border-radius: inherit;
    box-shadow: inset 0 0 0 rem(1) c(s5);
    img {
        border-radius: inherit;
        object-fit: cover;
        opacity: 0;
        &.active {
            animation: 400ms fadeIn forwards;
        }
    }
}
.lazy-meta-heading-blur {
    position: absolute;
    bottom: 0;
    left: 0;
    width: 100%;
    height: 50%;
    mask-image: linear-gradient(to top, #000 40%, rgba(black, 0));
    display: none;
    border-bottom-left-radius: inherit;
    border-bottom-right-radius: inherit;
    pointer-events: none;
    opacity: 0;
    animation: 250ms fadeIn forwards;
    @supports (mask-image: linear-gradient(to top, #000 40%, rgba(black ,0))) {
        display: block;
    }
    img {
        position: absolute;
        left: 0;
        bottom: 0;
        width: 100%;
        filter: blur(rem(12));
        transform: scale(1.5);
        opacity: 1;
        height: auto;
    }
}
