@import 'src/assets/scss/magic';
.no-data-holder {
    margin: auto;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    text-align: center;
    --guide: #{rem(220)};
    padding: rem($gap);
    max-width: 100%;
    @include mobile {
        padding: rem(50) rem(20);
    }
    .screen-search-holder & {
        width: 100%;
        @include mobile {
            border-radius: inherit;
            padding-bottom: rem(40);
        }
    }
    .no-data-artwork-holder {
        svg {
            width: var(--guide);
            margin: 0 0 rem(24);
            @include mobile {
                margin: 0 0 rem(15);
            }
        }
    }
    &.s-small {
        --guide: #{rem(160)};
        @include mobile {
            --guide: #{rem(140)};
        }
    }
    .card-holder &, .messages-grid & {
        position: absolute;
        top: 50%;
        left: 0;
        width: 100%;
        transform: translate3d(0, -50%, 0);
    }
}
.nd-title-holder {
    font: rem(16) / rem(26) $f;
    &:not(:last-child) {
        margin: 0 0 rem(6);
    }
}
.nd-sub-title-holder {
    opacity: 0.5;
    line-height: 1.5;
    @include mobile {
        font: rem(12) / rem(18) $f;
    }
    &:not(:last-child) {
        margin: 0 0 rem(24);
        @include mobile {
            margin: 0 0 rem(20);
        }
    }
}
.nd-cta-holder {
    min-width: var(--guide);
    display: flex;
    gap: rem(15);
    flex-wrap: wrap;
    justify-content: center;
    @include mobile {
        gap: rem(10);
    }
    .button {
        &:only-child {
            width: 100%;
        }
    }
}
.no-data-artwork-holder {
    width: 100%;
    margin: 0 0 rem(24);
    display: flex;
    justify-content: center;
    --theme-color-1: var(--svg-shade-light-1, #c0dcff);
    --theme-color-2: var(--svg-shade-light-2, #dceaff);
    --theme-color-3: var(--svg-shade-light-3, #ecf5ff);
    @include darkMode {
        --theme-color-1: var(--svg-shade-dark-1, #292f3c);
        --theme-color-2: var(--svg-shade-dark-2, #1c1e27);
        --theme-color-3: var(--svg-shade-dark-3, #212530);
    }
    @include mobile {
        margin: 0 0 rem(15);
    }
    .s-small & {
        .no-data-artwork-shape-1 {
            width: rem(78);
            height: rem(104);
            border-radius: rem(16);
            --icon-external-size: #{rem(42)};
        }
        .no-data-artwork-shape-2 {
            width: rem(50);
            height: rem(60);
            border-radius: rem(14);
        }
        .no-data-artwork {
            > small {
                transform: scale(0.9);
            }
        }
    }
}
.no-data-artwork {
    display: flex;
    justify-content: center;
    position: relative;
    > small {
        width: var(--size);
        height: var(--size);
        border-radius: var(--size);
        position: absolute;
        background: c(s5);
        &:nth-of-type(1) {
            --size: #{rem(11)};
            bottom: 105%;
            right: 105%;
        }
        &:nth-of-type(2) {
            --size: #{rem(8)};
            top: 40%;
            right: 140%;
        }
        &:nth-of-type(3) {
            --size: #{rem(8)};
            top: 100%;
            left: 110%;
        }
        &:nth-of-type(4) {
            --size: #{rem(4)};
            bottom: 110%;
            left: 40%;
        }
        &:nth-of-type(5) {
            --size: #{rem(8)};
            bottom: 60%;
            left: 130%;
        }
    }
}
.no-data-artwork-shape-1 {
    width: rem(112);
    height: rem(148);
    background: var(--theme-color-2);
    border-radius: rem(24);
    display: flex;
    justify-content: center;
    align-items: center;
    --icon-external-size: #{rem(56)};
    color: c(b);
    position: relative;
    overflow: hidden;
    @include darkMode {
        color: #292f3c;
    }
    &:before {
        content: '';
        position: absolute;
        top: -5%;
        left: -15%;
        width: 200%;
        padding-top: 200%;
        border-radius: 100%;
        background: var(--theme-color-3);
    }
}
.no-data-artwork-shape-2 {
    width: rem(50);
    height: rem(60);
    background: c(b);
    border-radius: rem(12);
    position: absolute;
    right: 50%;
    bottom: rem(-10);
    transform: translate3d(-60%, 0, 0);
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    gap: rem(4);
    box-shadow: 0 rem(20) rem(40) rem(-10) rgba(0, 0, 0, var(--pureMesmerizingShadow));
    @include darkMode {
        background: #1c1e27;
        box-shadow: none;
        --theme-color-3: #292f3c;
    }
    small {
        width: rem(16);
        height: rem(16);
        background: var(--theme-color-3);
        border-radius: rem(20);
        margin-bottom: rem(2);
    }
    i {
        height: rem(3);
        border-radius: rem(3);
        width: 60%;
        background: var(--theme-color-3);
        &:last-of-type {
            width: 40%;
        }
    }
}
