@import "src/assets/scss/magic";
$discount: #f87900;
.number-flow-r-holder {
    display: inline-flex;
    align-items: center;
    gap: rem(6);
    &.discount {
        $discount: mix(#f8bf00, $discount);
        color: darken($discount, 5);
        @include darkMode {
            color: lighten($discount, 5);
        }
        number-flow-react {
            color: inherit;
        }
    }
    .number-flow-discount {
        background: linear-gradient(35deg, #f8bf00, $discount);
        color: set-color($discount);
        font: 700 rem(11) / rem(20) $f;
        border-radius: rem(100);
        padding: 0 rem(6) 0 rem(8);
    }
}
