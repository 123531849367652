@import 'src/assets/scss/magic';
.horizontal-scroll-holder {
    width: 100%;
    overflow-x: auto;
    overflow-y: hidden;
    overscroll-behavior: none auto;
    scrollbar-width: none;
    @include desktop {
        touch-action: none;
    }
    &.ss-start {
        mask-image: linear-gradient(90deg, #000 calc(100% - #{rem(36)}), rgba(black ,0));
    }
    &.ss-middle {
        mask-image: linear-gradient(90deg, rgba(black ,0), #000 rem(36), #000 calc(100% - #{rem(36)}), rgba(black ,0));
    }
    &.ss-end {
        mask-image: linear-gradient(90deg, rgba(black ,0), #000 rem(36));
    }
    &::-webkit-scrollbar {
        display: none;
    }
    .horizontal-scroll-c {
        display: flex;
        align-items: flex-start;
        > * {
            flex-shrink: 0;
            + * {
                margin: 0 0 0 var(--horizontal-scroll-gap);
            }
        }
    }
}
