@import 'src/assets/scss/magic';
.react-autosuggest__container {
    @include mobile {
        .intro-holder & {
            width: 100%;
        }
    }
}
.search-input-holder {
    &:not(.a-extra) {
        width: 100%;
    }
    &.a-extra {
        $transition: 400ms;
        $secondaryTransition: 200ms;
        --width: #{rem(350)};
        --height: #{rem(60)};
        width: var(--width);
        height: var(--height);
        font: 450 rem(18) $f;
        margin: auto;
        position: relative;
        transition: $transition width;
        @include mobile {
            --width: 100%;
            max-width: rem(350);
            --height: #{rem(56)};
            font: 450 rem(16) $f;
        }
        input {
            position: absolute;
            top: 0;
            left: 50%;
            transform: translate(-50%, 0);
            width: var(--width);
            height: var(--height);
            background: c(b);
            padding: 0 calc(var(--height) / 2) 0 rem(100);
            font: inherit;
            border-radius: var(--height);
            transition: $transition width, $transition box-shadow, $transition padding;
            box-shadow: 0 rem(3) rem(6) rgba(black, 0.1);
            color: sc(b);
            @include mobile {
                padding: 0 calc(var(--height) / 2) 0 calc(var(--height) / 2 + #{rem(24 + 12)});
            }
            &:focus {
                @include desktop {
                    box-shadow: 0 rem(40) rem(60) rgba(black, 0.2);
                }
            }
        }
        label {
            position: absolute;
            top: 50%;
            left: 50%;
            transform: translate(-50%, -50%);
            display: grid;
            grid-template-columns: rem(24) auto;
            align-items: center;
            grid-column-gap: rem(16);
            font: inherit;
            pointer-events: none;
            white-space: nowrap;
            transition: 300ms left, 300ms transform, $transition grid-column-gap, $transition padding;
            padding: 0 calc(var(--height) / 2);
            @include mobile {
                grid-column-gap: rem(12);
                grid-template-columns: rem(24) auto;
            }
        }
        p {
            transition: $secondaryTransition opacity;
        }
        &.filled, &:focus-within {
            @include desktop {
                --width: #{rem(560)};
                input {
                    padding-left: calc(var(--height) / 2 + #{rem(24 + 16)});
                }
                label {
                    left: 0;
                    transform: translate(0, -50%);
                    padding: 0 rem(30);
                }
                p {
                    opacity: 0.5;
                }
            }
        }
        &.filled {
            @include desktop {
                p {
                    opacity: 0;
                }
            }
            @include mobile {
                input {
                    color: c(b);
                }
            }
        }
    }
    &.a-inline {
        &.s-big {
            --height: #{rem(64)};
            --font-size: #{rem(16)};
        }
        &.s-medium {
            --height: #{rem(50)};
            --font-size: #{rem(14)};
            @include mobile {
                --height: #{rem(54)};
            }
        }
    }
}
.inline-search-holder {
    position: relative;
    .icon {
        position: absolute;
        left: rem($guidePadding);
        top: 50%;
        transform: translateY(-50%);
        color: sc(b, 0.3);
    }
    input {
        height: var(--height);
        width: 100%;
        padding: 0 0 0 rem($guidePadding + 20 + 12);
        font: var(--font-size) $f;
        color: sc(b);
        @include placeholder {
            color: sc(b, 0.41);
        }
    }
}
.inline-search-line {
    position: absolute;
    top: 100%;
    display: block;
    height: rem(1);
    left: 0;
    width: 100%;
    .po-header & {
        top: auto;
        bottom: 0;
    }
    &:before {
        content: '';
        position: absolute;
        display: block;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        background: c(brand);
        transform: scaleX(0);
        transform-origin: left center;
        transition: 400ms transform;
    }
    .inline-search-holder:focus-within ~ & {
        &:before {
            transform: scaleX(1);
            transform-origin: left center;
        }
    }
}
