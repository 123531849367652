@import 'src/assets/scss/magic';
.logo {
    transition: transform 400ms;
    position: relative;
    overflow: hidden;
    z-index: 20;
    display: flex;
    &.og {
        @each $name, $color in $c {
            @include generateColorVariation($name, $color);
        }
    }
    svg {
        width: var(--width);
        height: var(--height, #{rem(36)});
        * {
            fill-rule: evenodd;
            clip-rule: evenodd;
            transition: 400ms fill;
        }
    }
    .logo-text {
        fill: c(brand);
        @include darkMode {
            fill: sc(b);
        }
        .mobile-navigation-opened & {
            fill: white;
        }
    }
    .logo-text-b {
        fill: sc(b);
        .mobile-navigation-opened & {
            fill: white;
        }
    }
    .logo-square {
        fill: c(hero);
    }
    .logo-square-letter {
        fill: sc(hero);
    }
    @include hover {
        .logo-text {
            fill: c(brand-hover);
            @include darkMode {
                fill: sc(b);
            }
            .mobile-navigation-opened & {
                fill: white;
            }
        }
        .logo-square {
            fill: c(hero-hover);
        }
    }
}
