@import 'src/assets/scss/magic';
.badge {
    position: absolute;
    right: 0;
    top: 0;
    transition: 400ms transform, 400ms max-width;
    transform: scale(var(--scale, 0));
    max-width: rem(100);
    &.active {
        --scale: 1;
    }
    &.transform {
        transform: translate(0, 0) scale(var(--scale, 0));
        &.has-content {
            transform: translate(50%, -50%) scale(var(--scale, 0));
        }
    }
    &:not(.has-content) {
        width: rem(8);
        height: rem(8);
    }
    &.p-static {
        position: static;
        &, &.has-content {
            transform: scale(var(--scale, 0));
        }
    }
    &.s-small {
        .badge-content {
            font: 700 rem(8) / rem(16) $f;
            &:not(:empty) {
                min-width: rem(16);
                padding: 0 rem(4);
            }
        }
    }
}
.badge-content {
    font: 700 rem(10) / rem(22) $f;
    text-align: center;
    background: c(brand);
    color: sc(brand);
    border-radius: rem(22);
    width: inherit;
    height: inherit;
    box-shadow: 0 0 0 rem(2) c(b);
    transition: 200ms color, 200ms background-color;
    li:not(.active) .a-light & {
        color: sc(b);
        background: sc(b, 0.1);
    }
    &:not(:empty) {
        min-width: rem(22);
        padding: 0 rem(6);
    }
    &.animate {
        animation: 0.6s rubberBand;
        @keyframes rubberBand {
            from, to {
                transform: scale3d(1, 1, 1);
            }
            30% {
                transform: scale3d(1.25, 0.75, 1);
            }
            40% {
                transform: scale3d(0.75, 1.25, 1);
            }
            50% {
                transform: scale3d(1.15, 0.85, 1);
            }
            65% {
                transform: scale3d(0.95, 1.05, 1);
            }
            75% {
                transform: scale3d(1.05, 0.95, 1);
            }
        }
    }
}
