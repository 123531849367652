@import "src/assets/scss/magic";
@import "node_modules/swiper/swiper.scss";
.home-onboarding-holder {
    width: 100%;
    overflow: hidden;
    background: linear-gradient(55deg, #2B5CD1, #97E632, #10CBD7, #FF39D4, #FF007A);
    padding: rem(1);
    border-radius: rem(48);
    position: relative;
    @include mobile {
        border-radius: rem(36);
    }
    > li {
        background: var(--pageBackground);
        border-radius: rem(47);
        overflow: hidden;
        display: grid;
        grid-template-columns: 100%;
        grid-row-gap: rem(36);
        padding: rem(60) 0 0;
        @include mobile {
            padding: rem(40) 0 0;
            grid-row-gap: rem(30);
            border-radius: rem(35);
        }
    }
    h2 {
        font: 900 f(32, 44) $f;
        color: c(brand);
        text-align: center;
        max-width: rem(540);
        margin: auto;
        text-wrap: balance;
        @supports (-webkit-background-clip: text) {
            background: linear-gradient(90deg, #5587FF 0%, #FF39D4 100%);
            -webkit-background-clip: text;
            color: transparent;
            span {
                color: c(brand);
            }
        }
        @include mobile {
            font-size: rem(20);
            padding: 0 rem(20);
        }
        &.en {
            max-width: rem(420);
        }
    }
    .blur {
        &.blur-1 {
            top: 50%;
            left: 0;
            opacity: 0.1;
            @include desktop {
                --width: 50%;
            }
        }
        &.blur-2 {
            opacity: 0.06;
            @include desktop {
                --width: 50%;
            }
        }
    }
}
.hog-swiper-holder {
    width: 100%;
    .swiper {
        padding: 0 rem(100);
        @include mobile {
            padding: 0 rem(30);
        }
        .hog-title-holder {
            width: auto;
            display: flex;
            align-items: center;
            justify-content: flex-start;
            font: 500 f(13) $f;
            gap: rem(12);
            color: c(base);
            transition: 400ms color;
            margin: auto 0;
            @include hover {
                color: sc(b, 0.8);
            }
            @include mobile {
                font-size: rem(12);
                gap: rem(6);
            }
            &.swiper-slide-thumb-active {
                color: sc(b, 0.9);
                @include hover {
                    color: sc(b, 1);
                }
            }
            svg {
                @include mobile {
                    transform: scale(0.7);
                }
            }
            br {
                &:not(:first-of-type) {
                    display: none;
                }
            }
            &:not(:last-child) {
                &:after {
                    content: "";
                    height: rem(1);
                    width: rem(150);
                    background: c(s5);
                    margin: 0 rem(20);
                    @include mobile {
                        width: rem(60);
                    }
                }
            }
            .button {
                color: white;
                background: black;
                @include darkMode {
                    color: black;
                    background: white;
                }
            }
        }
    }
    .lazy-image-holder {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        box-shadow: none;
        border-radius: rem(24);
    }
    .swiper-pagination {
        position: absolute;
        bottom: rem(20);
        left: 50%;
        background: c(b);
        display: flex;
        align-items: center;
        justify-content: center;
        transform: translate3d(-50%, 0, 0);
        height: rem(32);
        padding: 0 rem(20);
        z-index: 1;
        gap: rem(5);
        border-radius: rem(32);
        box-shadow: $pureMesmerizingShadow;
        &:empty {
            display: none;
        }
        span {
            $gap: -5;
            width: rem(6);
            height: rem(6);
            border-radius: rem(6);
            background: c(brand, 0.15);
            transform: scale(0.9);
            position: relative;
            transition: 400ms transform, 400ms background-color, 300ms margin;
            cursor: pointer;
            &:before {
                content: '';
                position: absolute;
                top: rem($gap);
                right: rem($gap);
                left: rem($gap);
                bottom: rem($gap);
            }
            @include hover {
                transform: scale(1.3);
            }
            &.swiper-pagination-bullet-active {
                background: c(brand);
                transform: scale(1.4);
                margin: 0 rem(3);
                &:before {
                    content: none;
                }
            }
        }
    }
}
.home-onboarding-item-holder {
    width: 100%;
    display: flex;
    justify-content: center;
    padding-top: 60%;
    margin-bottom: rem(-100);
    position: relative;
    transition: 400ms transform;
    transform: scale(0.95);
    @include mobile {
        padding-top: 220%;
    }
    .swiper-slide-active & {
        transform: scale(1);
    }
    img {
        flex: 0;
        width: auto;
        image-rendering: high-quality;
        filter: saturate(1.2);
        object-fit: contain;
        border-radius: rem(24);
    }
}
