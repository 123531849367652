@import 'src/assets/scss/magic';
.tag-holder {
    max-width: 100%;
    display: flex;
    align-items: center;
    background: c(s5);
    color: c(brand);
    height: rem(22);
    --height: #{rem(22)};
    font: rem(12) / rem(16) $f;
    font-weight: var(--tag-font-weight, 600);
    border-radius: rem(20);
    padding: 0 rem(12);
    user-select: auto;
    justify-content: center;
    gap: rem(6);
    position: relative;
    transition: 400ms border-color, 400ms color, 400ms background-color, 400ms transform;
    &.id-end {
        flex-direction: row-reverse;
    }
    &.cr-smooth {
        border-radius: rem(8);
    }
    &.s-extra-small {
        height: rem(18);
        padding: 0 rem(8);
        font-size: rem(9);
        gap: rem(4);
        small {
            padding: 0 rem(6);
            height: rem(16);
            margin-right: rem(-7);
        }
    }
    &.s-semi-small {
        height: rem(20);
        padding: 0 rem(8);
        font-size: rem(10);
        gap: rem(4);
    }
    &.s-small {
        font-size: rem(10);
        small {
            margin-right: rem(-11);
        }
    }
    &.s-big {
        height: rem(36);
        --height: #{rem(36)};
        font: 600 rem(14) / rem(18) $f;
        padding: 0 rem(18);
    }
    &.s-medium {
        height: rem(32);
        --height: #{rem(32)};
        padding: 0 rem(18);
        .icon-linked {
            margin: 0 0 0 rem(-4);
        }
    }
    &.s-default {
        height: rem(26);
        --height: #{rem(26)};
        font-size: rem(11);
        font-weight: 600;
        small {
            margin-right: rem(-9);
        }
    }
    &.clickable {
        &:active {
            transform: scale(0.92);
        }
    }
    &.c-default {
        &.clickable {
            cursor: pointer;
            @include hover {
                color: c(brand-accent);
            }
        }
    }
    &:not(.c-default) {
        color: c(brand);
        background: c(brand, 0.1);
        &.clickable {
            @include hover {
                background: c(brand, 0.15);
                color: c(brand-hover);
                border-color: c(brand, 0);
            }
        }
        &.c-error {
            @include over(brand, error);
        }
        &.c-warning {
            @include over(brand, warning);
        }
        &.c-success {
            @include over(brand, success);
        }
        &.c-base {
            @include over(brand, base);
        }
        &.c-grey {
            @include over(brand, grey);
        }
        &.c-complimentary {
            @include over(brand, complimentary);
        }
    }
    small {
        display: flex;
        align-items: center;
        justify-content: center;
        text-align: center;
        flex-shrink: 0;
        height: rem(20);
        min-width: rem(20);
        background: c(b);
        color: c(brand);
        font-weight: 700;
        border-radius: rem(20);
        padding: 0 rem(8);
        margin-right: calc((var(--height) - #{rem(20)}) * -1);
        transition: 400ms background;
    }
    &.a-outline {
        border: rem(1) solid c(brand, 0.5);
        background: none;
        small {
            background: c(brand, 0.05);
        }
        &.clickable {
            @include hover {
                background: c(brand, 0.1);
                border-color: c(brand);
                small {
                    background: sc(brand);
                }
            }
        }
    }
}
