@import 'src/assets/scss/magic';
.card-holder {
    --font-size: #{rem(14)};
    --line-height: #{rem(24)};
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    position: relative;
    overflow: hidden;
    background: c(b);
    color: sc(b);
    border-radius: rem(24);
    transition: box-shadow 400ms, transform 400ms;
    padding: rem($gap);
    font: var(--font-size) / var(--line-height) $f;
    &.overflow-visible {
        overflow: visible;
    }
    @include mobile {
        padding: rem(24);
        scroll-snap-align: center;
    }
    &.border {
        box-shadow: inset 0 0 0 rem(1) sc(b, 0.08);
    }
    &.shadow {
        box-shadow: $pureMesmerizingShadow;
        @include mobile {
            box-shadow: 0 rem(6) rem(30) rem(-20) rgba(0, 0, 0, 0.5);
        }
    }
    &.fit {
        height: 100%;
        flex: auto;
        > li {
            display: flex;
            height: 100%;
        }
    }
    &.a-minimal {
        display: grid;
        grid-template-columns: auto 1fr;
        grid-column-gap: rem(15);
        .card-holder-title {
            margin: 0 0 rem(6);
        }
    }
    &.a-clean {
        flex-direction: row;
        overflow-y: auto;
        overflow-x: hidden;
        &.overflow-visible {
            overflow: visible;
        }
        > li {
            display: flex;
            width: 100%;
        }
    }
    &.a-expanded {
        > li {
            width: 100%;
            display: flex;
            flex-direction: column;
            &.card-cta-holder {
                width: auto;
                padding: rem(20) 0 0;
                margin: auto 0 0 auto;
                font-size: rem(24);
                color: c(brand);
                transition: transform 400ms;
                min-height: rem(44);
            }
        }
        &.home-intro-card {
            > li {
                p {
                    @include desktop {
                        max-width: rem(235);
                    }
                }
            }
        }
    }
    .card-holder-title {
        --font-size: #{rem(18)};
        --line-height: #{rem(24)};
        font: 700 var(--font-size) / var(--line-height) $f;
        margin: 0 0 rem(10);
        color: sc(b);
        text-transform: capitalize;
        display: flex;
        .home-top-cards & {
            text-transform: none;
        }
    }
    @include hover-not-active {
        &.clickable {
            transform: translateY(rem(-6));
            &.shadow {
                box-shadow: $pureMesmerizingShadowHover;
            }
            .card-cta-holder {
                transform: translateX(rem(6));
            }
        }
    }
}
.card-icon {
    width: rem(50);
    height: rem(50);
    display: flex;
    justify-content: center;
    align-items: center;
    background: c(s5);
    border-radius: 100%;
    color: c(brand);
    margin: 0 0 rem(20);
}
.card-icon-skeleton {
    width: rem(30);
    height: rem(30);
    border-radius: 100%;
}
.card-native-icon {
    width: rem(16);
    height: rem(16);
    box-shadow: inset 0 0 0 c(brand), inset 0 0 0 rem(5) c(s5);
    border-radius: 100%;
    transition: box-shadow 400ms;
    margin: rem(2) 0;
    @include hover-not-active('.card-holder') {
        box-shadow: inset 0 0 0 rem(5) c(brand), inset 0 0 0 rem(5) c(s5);
    }
}
.card-content {
    width: 100%;
    a {
        position: relative;
        z-index: 1;
    }
}
.card-external-head {
    width: 100%;
    display: grid;
    grid-template-columns: 1fr auto;
    align-items: center;
    grid-column-gap: rem(8);
    @include mobile {
        padding: rem(5) 0 rem(20);
        border-bottom: rem(1) solid c(s5);
        &:not(:first-child) {
            padding: rem(10) 0 rem(20);
        }
    }
    @include desktop {
        margin: 0 0 rem(12);
        min-height: rem(40);
    }
    .card-eh-title {
        font: f(16) $f;
        text-transform: capitalize;
        @include mobile {
            font-weight: 600;
            color: c(brand);
        }
        small {
            display: inline-flex;
            vertical-align: middle;
            margin: 0 0 0 1ch;
            min-width: rem(30);
            height: rem(30);
            background: c(s5);
            align-items: center;
            justify-content: center;
            font: 600 f(12) $f;
            padding: 0 rem(8);
            border-radius: rem(15);
            color: c(brand);
        }
    }
    .card-head-holder {
        width: rem(180);
        @include mobile {
            width: rem(120);
        }
    }
}
