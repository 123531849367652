@import "src/assets/scss/magic";
.count-input-holder {
    --size: #{rem(32)};
    display: flex;
    align-items: center;
    height: var(--size);
    border-radius: var(--size);
    background: c(s5);
    color: c(brand);
    border: rem(1) solid c(brand, 0.08);
    padding: 0 rem(2);
    transition: 300ms border-color, 300ms background-color;
    &.disabled {
        background: none;
        pointer-events: none;
        border-color: c(brand, 0.15);
        > * {
            opacity: 0.5;
        }
    }
    &.focused {
        background: c(s5, 0);
        border-color: c(brand, 1);
    }
    @include hover {
        background: c(s5, 0);
        border-color: c(brand, 1);
    }
    button {
        width: rem(24);
        height: var(--size);
        display: flex;
        align-items: center;
        justify-content: center;
        cursor: pointer;
        opacity: 0.8;
        color: inherit;
        transition: 300ms color, 300ms opacity;
        padding: 0 0 0 rem(2);
        &:disabled {
            pointer-events: none;
            opacity: 0.2;
        }
        &:last-child {
            padding: 0 rem(2) 0 0;
        }
        @include hover {
            opacity: 1;
        }
        svg {
            transition: 400ms transform;
        }
        &:active {
            svg {
                transform: scale(0.8);
            }
        }
    }
    input {
        text-align: center;
        width: rem(22);
        height: var(--size);
        color: inherit;
        font: 700 rem(12) $f;
        transition: 300ms color, 300ms opacity;
    }
}
