@import 'src/assets/scss/magic';
.information-holder {
    width: 100%;
    align-items: flex-start;
    text-align: left;
    color: c(brand);
    &.t-error {
        @include over(brand, error);
    }
    &.a-box, &.a-outline, &.a-light {
        display: grid;
        border-radius: rem(12);
        background: c(brand, 0.05);
        grid-template-columns: auto 1fr;
        padding: rem($guidePadding);
        grid-column-gap: rem(8);
    }
    &.a-outline {
        background: none;
        border-radius: rem(16);
        border: rem(1) dashed c(brand);
    }
    &.a-light {
        background: none;
        padding: rem(8) 0;
    }
    &.a-inline {
        margin: rem(6) 0 0;
    }
}
.information-content {
    font: rem(14) / rem(24) $f;
    strong {
        font-weight: 600;
    }
    &::first-letter {
        text-transform: uppercase;
        user-select: none;
    }
}
