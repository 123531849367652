@import 'magic';
.skeleton-group {
    display: flex;
    flex-direction: column;
    width: 100%;
}
.skeleton-blink, .skeleton-wave.t-blink, .icon.skeleton {
    background: none;
    border: rem(1) solid c(brand, 0.2) !important;
    animation: skeleton-blink 1s infinite linear;
    &.no-animate, .no-animate & {
        animation: none;
    }
    &:not(.input-element) {
        border-radius: inherit;
    }
    @keyframes skeleton-blink {
        50% {
            opacity: 0.2;
        }
    }
    &.full-radius {
        border-radius: 100%;
    }
    &.full-c-radius {
        border-radius: rem(100);
    }
    &.s-32 {
        width: rem(32);
        height: rem(32);
    }
}
.skeleton-wave {
    display: block;
    min-height: calc(var(--font-size) / 3);
    margin: calc((var(--line-height) - (var(--font-size) / 3)) / 2) 0;
    width: 60%;
    border-radius: var(--font-size);
    &:not(.t-blink) {
        background: linear-gradient(to right, c(s5) 8%, c(s5, 0.6) 18%, c(s5) 33%);
        animation: 1s skeleton-blink infinite linear;
        &.no-animate, .no-animate & {
            animation: none;
        }
    }
    .skeleton-group & {
        &:nth-child(odd) {
            width: 80%;
        }
        &:last-child {
            width: 90%;
        }
    }
    &.small {
        width: 40%;
    }
    &.medium {
        width: 70%;
    }
    &.wide {
        width: 90%;
    }
    &.min-60 {
        min-width: rem(60);
        width: auto;
    }
    &.min-110 {
        min-width: rem(110);
        width: auto;
    }
    &.h-32 {
        height: rem(32);
        margin: 0;
        border-radius: rem(8);
        background-size: rem(200);
        &:not(.t-blink) {
            animation-duration: 10s;
        }
    }
    &.sq-20 {
        width: rem(20);
        height: rem(20);
        margin: 0;
    }
    &.sq-44 {
        width: rem(44);
        height: rem(44);
        margin: 0;
        border-radius: 100%;
        animation-duration: 10s;
        background-size: rem(200);
    }
    &.full-radius {
        border-radius: rem(100);
    }
    &.b-32 {
        width: rem(32);
        height: rem(32);
        margin: 0;
    }
    &.b-40 {
        width: rem(40);
        height: rem(40);
        margin: 0;
    }
    &.m-l {
        margin: calc((var(--line-height) - var(--font-size)) / 2) 0;
    }
    &.inline {
        display: inline-block;
    }
}
