@import "src/assets/scss/magic";
.locked-screen-text {
    width: rem(300);
    max-width: 80vw;
    white-space: normal;
    text-align: left;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    gap: rem(8);
    padding: rem(6) 0;
    font: 600 rem(12) / rem(18) $f;
    @include mobile {
        width: rem(270);
    }
    a {
        display: inline-flex;
        vertical-align: baseline;
        align-items: center;
        font-weight: 700;
        gap: rem(4);
        color: c(brand);
        padding-right: rem(2);
    }
    .toast-contents-holder & {
        padding: 0;
        font-weight: 400;
        a {
            font-weight: 600;
        }
    }
}
