@import "src/assets/scss/magic";
.partnership-up-sale-row-holder {
    width: 100%;
    padding: rem(50);
    position: relative;
    border-radius: rem(48);
    background: rgba(#FF00A1, 0.03);
    overflow: hidden;
    @include mobile {
        border-radius: rem(36);
        padding: rem(36);
        width: calc(100% + var(--guide-padding));
        margin: 0 calc(-1 * (var(--guide-padding) / 2));
    }
    &.t-agencies {
        --theme-color: #FF00A1;
        @include darkMode {
            --theme-color: #fd6bc7;
        }
        > svg {
            bottom: 0;
            right: 0;
            &:first-of-type {
                transform: translate3d(-50%, 50%, 0);
            }
            &:last-of-type {
                transform: translate3d(11%, 27%, 0);
            }
        }
    }
    &.t-billboards {
        background: #0A0F1D;
        color: white;
        --theme-color: #C3FFE9;
        @include darkMode {
            --theme-color: #00bf7b;
            background: #f3f3f5;
            color: c(b);
        }
        > svg {
            left: 50%;
            bottom: 0;
            transform: translate3d(-45%, 55%, 0) rotate(-15deg);
        }
    }
    > svg {
        position: absolute;
    }
    > ul {
        display: grid;
        grid-template-columns: repeat(2, 1fr);
        align-items: center;
        gap: rem(40);
        @include mobile {
            gap: rem(24);
            grid-template-columns: 100%;
        }
    }
    h3 {
        text-align: right;
        font: 850 rem(38) / 1.5 $f;
        @include mobile {
            text-align: center;
            font-size: rem(24);
        }
    }
}
.partnership-up-sale-row-cta {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    gap: rem(12);
    font: f(16, 26) $f;
    @include mobile {
        font-size: rem(14);
        gap: rem(24);
    }
    @include desktop {
        padding: rem(20) 0 0;
    }
}
