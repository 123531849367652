@import 'src/assets/scss/magic';
.image-holder {
    width: 100%;
    display: flex;
    position: relative;
    transition: 400ms transform, 400ms box-shadow;
    box-shadow: $pureMesmerizingShadow;
    overflow: hidden;
    border-radius: rem(24);
    @include mobile {
        padding: 100% 0 0;
        border-radius: rem(16);
    }
    picture {
        width: 100%;
        display: flex;
        transition: 400ms filter;
        @include mobile {
            position: absolute;
            top: 0;
            left: 0;
            width: 100%;
            height: 100%;
        }
        img {
            display: flex;
            object-fit: cover;
            width: 100%;
            height: 100%;
        }
    }
    @include hover {
        box-shadow: $pureMesmerizingShadowHover;
        transform: translateY(rem(-6));
        picture {
            filter: saturate(1.5);
        }
    }
}
.image-cta-holder {
    --gap: #{rem($gap)};
    position: absolute;
    bottom: var(--gap);
    right: var(--gap);
    @include mobile {
        --gap: #{rem(20)};
        left: 0;
        right: 0;
        display: flex;
        justify-content: center;
    }
}
