@import "src/assets/scss/magic";
.screen-popular-times-holder {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    padding: rem(12) rem(16) 0;
    @include mobile {
        padding: rem(16) rem(16) 0;
    }
    h2 {
        font: 500 f(16) $f;
        margin: 0 0 rem(4);
        color: mix(#16a9b6, #07bf48);
        @include mobile {
            font-size: rem(18);
        }
        @supports (-webkit-background-clip: text) {
            background: linear-gradient(45deg, #16a9b6, #07bf48);
            color: transparent;
            -webkit-background-clip: text;
        }
    }
    h3 {
        font: f(12, 18) $f;
        color: sc(b, 0.6);
    }
    .screen-popular-times-chart {
        position: relative;
        @include mobile {
            width: calc(100% + #{rem(32)});
            margin: 0 rem(-16);
            padding: 0 rem(16);
            overflow: auto hidden;
            &::-webkit-scrollbar {
                display: none;
            }
        }
    }
    .loader-spinner {
        width: rem(40);
        height: rem(40);
    }
}
.screen-popular-times-weekdays {
    display: grid;
    align-items: center;
    grid-template-columns: repeat(7, 1fr);
    grid-template-areas: 'mon tue wed thu fri sat sun';
    gap: rem(12);
    position: relative;
    margin: rem(12) rem(6);
    small {
        position: absolute;
        grid-area: mon;
        height: 100%;
        width: 100%;
        left: 0;
        bottom: 0;
        z-index: 0;
        transition: 300ms transform;
        &:before {
            content: "";
            position: absolute;
            background: sc(b);
            inset: rem(-3) rem(-6);
            border-radius: rem(20);
        }
    }
    button {
        font: 600 f(10) $f;
        text-transform: uppercase;
        color: sc(b, 0.7);
        cursor: pointer;
        position: relative;
        z-index: 1;
        transition: 300ms color;
        &:before {
            content: "";
            position: absolute;
            inset: rem(-6);
        }
        @include hover {
            color: sc(b, 0.8);
        }
        &.active {
            color: c(b);
            @for $i from 1 to 8 {
                &:nth-child(#{$i}) ~ small {
                    transform: translate3d(calc(#{($i - 1) * 100 + '%'} + #{rem(12 * ($i - 1))}), 0, 0);
                }
            }
        }
    }
}
