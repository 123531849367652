@import "src/assets/scss/magic";
.screen-paper-holder {
    width: 100%;
    border-radius: rem(16);
    display: grid;
    gap: rem(4);
    grid-template-columns: rem(260) 1fr;
    align-items: center;
    transition: 500ms box-shadow;
    --image-height: #{rem(200)};
    --details-padding: #{rem(12)};
    --details-gap: #{rem(12)};
    --title-size: #{rem(15)};
    --slider-top-size: #{rem(8)};
    &:not(.a-column) {
        padding: rem(4);
        box-shadow: $pureMesmerizingShadowCenter, 0 0 0 rem(1) c(brand, 0);
        &.highlighted {
            --pureMesmerizingShadowCenter: 0;
            box-shadow: $pureMesmerizingShadowCenter, 0 0 0 rem(1) c(brand, 1);
        }
    }
    &.a-row {
        grid-template-columns: rem(160) 1fr;
        --image-height: #{rem(144)};
        --slider-top-size: #{rem(4)};
        --details-padding: #{rem(0)} #{rem(12)};
    }
    &.a-column {
        gap: rem(10);
        grid-template-columns: 100%;
        --title-size: #{rem(14)};
        --details-gap: #{rem(10)};
        --details-padding: #{rem(0)};
        --image-height: #{rem(172)};
    }
    figure {
        position: relative;
        height: var(--image-height);
        overflow: hidden;
        transform: translate3d(0, 0, 0);
        contain: paint;
    }
}
.sp-screen-slider-top {
    pointer-events: none;
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    padding: var(--slider-top-size);
    gap: var(--slider-top-size);
    display: flex;
    justify-content: space-between;
    align-items: flex-start;
    > li {
        &:not(:empty) {
            background: c(b);
            border-radius: rem(100);
            box-shadow: $pureMesmerizingShadowCenter;
        }
    }
}
.sp-screen-availability-status {
    display: flex;
    align-items: center;
    gap: rem(4);
    height: rem(30);
    padding: 0 rem(10);
    font: 500 f(12) $f;
    color: c(error);
    &.available {
        color: c(success);
    }
}
.sp-campaign-actions-holder {
    pointer-events: all;
    > .flex-component {
        padding-right: rem(8);
    }
    * {
        border: 0 !important;
        background: none !important;
    }
}
.screen-paper-rating-holder {
    position: absolute;
    top: rem(8);
    left: rem(8);
    background: c(b);
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 0 rem(8) 0 rem(6);
    height: rem(24);
    border-radius: rem(24);
}
.screen-paper-details-holder {
    display: grid;
    grid-template-columns: 100%;
    padding: var(--details-padding-external, var(--details-padding));
    gap: var(--details-gap);
}
.screen-paper-heading-info {
    font: f(10) $f;
    color: sc(b, 0.6);
    letter-spacing: rem(0.2);
}
.screen-paper-name {
    font: 500 f(15, 22) $f;
    font-size: var(--title-size);
    color: sc(b);
    transition: 400ms color;
    min-width: 0;
    @include hover {
        color: c(brand);
    }
}
.sp-meta-details-holder {
    align-items: center;
    gap: rem(12);
    display: grid;
    grid-template-columns: repeat(4, 1fr);
    .a-column & {
        gap: rem(16);
        display: flex;
        align-items: center;
    }
}
.sp-meta-detail-item {
    color: sc(b, 0.6);
    strong {
        font: 400 f(13, 18) $f;
        .screen-paper-holder.a-default & {
            font-size: rem(14);
        }
    }
    p {
        font: f(11, 14) $f;
    }
    svg {
        flex-shrink: 0;
    }
}
.sp-meta-detail-action {
    transition: 400ms transform, 400ms color, 400ms opacity;
    color: sc(b, 0.41);
    flex-shrink: 0;
    position: relative;
    &:not(.disabled) {
        cursor: pointer;
        @include hover {
            transform: scale(1.1);
            color: sc(b, 0.48);
        }
    }
    &.popover-opened {
        color: c(success);
        @include hover {
            color: c(success-hover);
        }
    }
}
