@import 'src/assets/scss/magic';
.checkbox-holder {
    display: flex;
    align-items: center;
    position: relative;
    cursor: pointer;
    line-height: rem(22);
    &.locked {
        cursor: default;
    }
    input {
        position: absolute;
        top: 0;
        left: 0;
        opacity: 0;
        pointer-events: none;
        &:checked ~ small {
            box-shadow: inset 0 0 0 rem(20) c(brand);
            border-color: c(brand);
        }
        &:not(:checked) ~ small {
            .icon-a-check {
                transform: scale(0);
            }
        }
    }
    small {
        border: rem(1) solid var(--cr-border-color-unchecked, #{sc(b, 0.14)});
        display: flex;
        justify-content: center;
        align-items: center;
        width: rem(20);
        height: rem(20);
        border-radius: rem(6);
        transition: border-color 400ms, box-shadow 400ms;
        color: sc(brand);
        box-shadow: inset 0 0 0 c(brand);
        .icon-a-check {
            display: block;
            transition: 300ms transform;
            font-size: rem(16);
        }
        &:after {
            content: '';
            display: block;
            width: rem(10);
            height: rem(2);
            background: sc(brand);
            position: absolute;
            top: 50%;
            left: 50%;
            transform: translate(-50%, -50%) scale(0);
            transition: 300ms transform;
        }
    }
    @media (hover: hover) {
        &:hover, label:hover & {
            --cr-border-color-unchecked: #{c(brand)};
        }
    }
    .ch-label {
        margin: 0;
        margin-inline-start: rem(16);
    }
    &.a-reverse {
        small {
            order: 1;
        }
        .ch-label {
            margin: 0;
            margin-inline-end: rem(10);
        }
    }
    &.intermediate {
        small {
            box-shadow: inset 0 0 0 rem(20) c(brand);
            border-color: c(brand);
            &:after {
                transform: translate(-50%, -50%) scale(1);
            }
        }
    }
}
