@import 'src/assets/scss/magic';
.menu-items-wrapper {
    width: 100%;
    overflow: hidden;
    white-space: nowrap;
    min-width: rem(280);
    transition: 500ms height;
    > ul {
        --x: calc(-1 * var(--translate-x));
        display: flex;
        min-width: 100%;
        transition: 300ms transform;
        transform: translateX(var(--x));
        > li {
            width: 100%;
            flex-shrink: 0;
            display: flex;
            flex-direction: column;
            padding: rem(6) 0;
        }
    }
    .v-divider {
        margin: rem(6) 0;
    }
}
