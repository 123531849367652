@import "src/assets/scss/magic";
.agencies-graphics-holder {
    width: rem(689);
    height: rem(571);
    position: relative;
    --svg-fill: #E2E9F5;
    @include mobile {
        transform: scale(0.7);
        margin: rem(-140) 0 rem(-46);
    }
    @include darkMode {
        --svg-fill: #727990;
    }
    > li {
        position: absolute;
        transition: 400ms opacity;
        transition-delay: 1s;
        &.hidden {
            opacity: 0;
            transition-delay: 0ms;
        }
        &:nth-child(1) {
            top: rem(16);
            left: rem(478);
        }
        &:nth-child(2) {
            top: rem(154);
            left: rem(20);
        }
        &:nth-child(3) {
            top: rem(36);
            left: rem(186);
        }
        &:nth-child(4) {
            top: rem(258);
            left: rem(70);
        }
        &:nth-child(5) {
            top: rem(372);
            left: 0;
        }
        &:nth-child(6) {
            bottom: 0;
            left: rem(156);
        }
        &:nth-child(7) {
            bottom: rem(-10);
            left: rem(351);
        }
        &:nth-child(8) {
            top: rem(192);
            right: rem(68);
            z-index: 1;
        }
        &:nth-child(9) {
            top: 0;
            left: rem(94);
        }
        &:nth-child(10) {
            top: rem(340);
            right: 0;
        }
        &:nth-child(11) {
            top: rem(60);
            left: 50%;
            transform: translateX(-50%);
            &:before {
                content: '';
                position: absolute;
                background: rgba(black, 0.2);
                height: 80%;
                bottom: rem(-20);
                width: 100%;
                left: 0;
                filter: blur(rem(40));
                transform: scale(0.8);
            }
        }
    }
    .lazy-image-holder {
        box-shadow: $pureMesmerizingShadow;
        border-radius: rem(20);
        display: block;
        width: var(--width);
        img {
            filter: saturate(1.2);
        }
        &.no-shadow {
            box-shadow: none;
        }
        &.no-loader {
            .loader-spinner {
                display: none;
            }
        }
    }
}
.agencies-graphics-box {
    display: flex;
    align-items: center;
    justify-content: center;
    text-align: center;
    white-space: nowrap;
    background: c(b);
    border-radius: rem(30);
    flex-direction: column;
    padding: rem(22) rem(30);
    font: 700 f(10) $f;
    letter-spacing: 0.1em;
    text-transform: uppercase;
    color: var(--theme);
    gap: rem(8);
    box-shadow: $pureMesmerizingShadow;
    --pureMesmerizingShadow: 0.2;
}
