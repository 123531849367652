@import "magic";
@import "../fonts/caveat";
.partnership-landing-holder {
    display: grid;
    grid-template-columns: 100%;
    gap: rem(60);
    padding: rem(60) 0 rem(100);
    @include mobile {
        gap: rem(40);
        padding: rem(60) 0 rem(30);
    }
}
.partnership-vice-versa-cards {
    display: grid;
    gap: rem(30);
    grid-template-columns: repeat(2, 1fr);
    @include mobile {
        grid-template-columns: 100%;
        gap: rem(20);
    }
}
.partnership-hr {
    width: 100%;
    height: rem(1);
    background: linear-gradient(90deg, #4CB300 0%, #1CCCB3 100%);
    &.t-blue {
        background: linear-gradient(90deg, #5587FF 0%, #FF39D4 100%);
    }
}
.link-effect {
    position: relative;
    display: inline-block;
    transform: translate3d(0, 0, 0);
    &:after, &:before {
        content: '';
        position: absolute;
        bottom: rem(3);
        left: 0;
        width: 100%;
        height: rem(1);
        background: currentColor;
        transform-origin: right;
        transform: scaleX(1) translateZ(0);
        transition: transform .6s cubic-bezier(.165, .84, .44, 1) .3s;
    }
    &:after {
        transform-origin: left;
    }
    &:not(:hover):after, &:hover:before {
        transform: scaleX(0) translateZ(0);
        transition: transform .6s cubic-bezier(.165, .84, .44, 1);
    }
    &.hover-active {
        &:not(:hover):not(.active) {
            &:before {
                transform: scaleX(0) translateZ(0);
            }
        }
    }
}
.scroll-to-target-holder {
    width: 100%;
    scroll-margin-top: var(--header-height);
}
