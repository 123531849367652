@import 'src/assets/scss/magic';
$types: (
    success: (
        color: success,
        icon: '\e935',
    ),
    danger: (
        color: error,
        icon: '\ea0c',
    ),
    info: (
        color: brand,
        icon: '\ea1a',
    ),
    warning: (
        color: warning,
        icon: '\ea19',
    ),
);
.rnc__base {
    position: fixed;
    z-index: 1001;
    pointer-events: none;
}
.rnc__notification-container--top-right {
    position: fixed;
    right: 0;
    top: calc(#{rem($guidePadding)} + var(--header-height) + var(--filters-height, 0px) + env(safe-area-inset-top, 0px));
    z-index: 110;
    transform: translate3d(0, 0, 0);
    transition: 400ms top;
    .modal-focus-on:has(.full-cover) ~ * &, :has(header.a-logo) & {
        top: calc(#{rem($guidePadding)} + var(--filters-height, 0px) + env(safe-area-inset-top, 0px));
    }
    @include mobile {
        width: 100%;
        padding: 0 rem(15);
        top: calc(#{rem(15)} + var(--header-height) + var(--filters-height, 0px) + env(safe-area-inset-top, 0px));
    }
}
.rnc__notification {
    $row: 24;
    padding: 0 rem($guidePadding);
    width: rem(340 + ($guidePadding * 2)) !important;
    @include mobile {
        padding: 0;
        width: 100% !important;
    }
    .rnc__notification-item {
        width: 100%;
        pointer-events: auto;
        border-radius: rem(12);
        margin: 0 0 rem(12);
        padding: rem($gap);
        grid-column-gap: rem(14);
        box-shadow: $pureMesmerizingShadow;
        background: c(b);
        position: relative;
        @include mobile {
            padding: rem(24);
        }
        @each $typeName, $typeObject in $types {
            &--#{$typeName} {
                @include over(hero, map-get($typeObject, 'color'));
                .rnc__notification-content {
                    &:before {
                        content: map-get($typeObject, 'icon');
                    }
                }
            }
        }

        &:not(.hide) {
            opacity: 0;
            transform: translate3d(300rem, 0, 0) scaleX(3);
            animation: 1s bounceInRight forwards;
            @keyframes bounceInRight {
                from, 60%, 75%, 90%, to {
                    animation-timing-function: cubic-bezier(0.215, 0.61, 0.355, 1);
                }
                from {
                    opacity: 0;
                    transform: translate3d(rem(3000), 0, 0) scaleX(3);
                }
                60% {
                    opacity: 1;
                    transform: translate3d(rem(-25), 0, 0) scaleX(1);
                }
                75% {
                    transform: translate3d(rem(10), 0, 0) scaleX(0.98);
                }
                90% {
                    transform: translate3d(rem(-5), 0, 0) scaleX(0.995);
                }
                to {
                    opacity: 1;
                    transform: translate3d(0, 0, 0);
                }
            }
        }
        &.hide {
            animation: 1s bounceOutRight forwards;
            @keyframes bounceOutRight {
                20% {
                    opacity: 1;
                    transform: translate3d(rem(-20), 0, 0) scaleX(0.9);
                }
                to {
                    opacity: 0;
                    transform: translate3d(rem(2000), 0, 0) scaleX(2);
                }
            }
        }
    }
    .rnc__notification-content {
        width: 100%;
        display: grid;
        grid-column-gap: rem(6);
        grid-row-gap: rem(10);
        grid-template-columns: auto 1fr auto;
        font: f(12, 20) $f;
        &:before {
            content: '';
            font: rem(24) / 1 $fi;
            color: c(hero);
            display: flex;
            width: rem($row);
            height: rem($row);
            justify-content: center;
            align-items: center;
            order: 0;
        }
        ::first-letter {
            text-transform: uppercase;
            user-select: none;
        }
    }
    .rnc__notification-close-mark {
        order: 2;
        font: rem(18) / 1 $fi;
        width: rem($row);
        height: rem($row);
        display: flex;
        justify-content: center;
        align-items: center;
        color: sc(b, 0.8);
        border-radius: 100%;
        transition: 400ms background;
        cursor: pointer;
        @include hover {
            background: sc(b, 0.05);
        }
        &:before {
            content: '\ea78';
        }
    }
    .rnc__notification-title {
        order: 1;
        font: 500 f(14, $row) $f;
        color: c(hero);
        overflow: hidden;
        white-space: nowrap;
        text-overflow: ellipsis;
    }
    .rnc__notification-message {
        order: 4;
        grid-column: span 3;
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        code {
            margin: rem(12) 0 0;
            background: rgba(black, 0.2);
            border-radius: rem(4);
            padding: rem(8);
            pre {
                white-space: normal;
                word-break: break-all;
            }
        }
    }
    .rnc__notification-timer {
        order: 5;
        height: rem(2);
        border-radius: rem(2);
        grid-column: span 3;
        background: c(hero, 0.1);
        &-filler {
            background: c(hero);
            border-radius: inherit;
            width: 0;
            height: 100%;
            @keyframes timer {
                from {
                    width: 0;
                }
                to {
                    width: 100%;
                }
            }
        }
    }
}
.toast-contents-holder {
    width: 100%;
    display: grid;
    grid-template-columns: 100%;
    grid-row-gap: rem(16);
    > li {
        .toast-contents-cta {
            display: none;
        }
        &.toast-contents-cta {
            margin: 0 0 rem(6);
        }
    }
}
