@import 'src/assets/scss/magic';
@mixin hover($class: null) {
    @media (hover: hover) {
        &:hover {
            @content;
        }
    }
    &.force-focus {
        &:focus {
            &:not(:active) {
                @content;
            }
        }
    }
    &:not(.force-focus) {
        &:active {
            @content;
        }
    }
    @if $class != null {
        &#{$class} {
            @content;
        }
    }
}
.button {
    --box-shadow: 0 0 0;
    display: flex;
    max-width: 100%;
    align-items: center;
    justify-content: center;
    text-align: center;
    height: var(--height);
    min-width: var(--height);
    font: 550 var(--font-size) / rem(24) $f;
    background: c(hero);
    color: sc(hero);
    cursor: pointer;
    box-shadow: var(--box-shadow), 0 0 0 0 c(hero, 0.5);
    position: relative;
    @include getSizes;
    transition: 400ms background, 400ms color, 400ms box-shadow, 400ms opacity;
    gap: var(--button-external-gap, #{rem(12)});
    &.force-focus {
        transition: 400ms background, 400ms color, 200ms box-shadow, 400ms opacity;
        &:focus {
            &:not(:active) {
                box-shadow: var(--box-shadow), 0 0 0 rem(3) c(hero, 0.5);
            }
        }
    }
    &.gradient {
        &:before {
            content: '';
            position: absolute;
            top: 0;
            left: 0;
            width: 100%;
            height: 100%;
            opacity: 0;
            border-radius: inherit;
            background: white;
            transition: 400ms opacity;
        }
        @include hover {
            &:before {
                opacity: 0.1;
            }
        }
    }
    &.id-end {
        flex-direction: row-reverse;
    }
    ::first-letter {
        user-select: none;
        text-transform: capitalize;
    }
    & {
        @mixin button-hover {
            background: c(hero-hover);
            .icon, .button-addon-holder svg {
                transform: scale(0.9);
            }
            .icon-a-arrow-right {
                transform: translateX(rem(5));
            }
        }
        @include hover {
            @include button-hover;
        }
        @include media-hover {
            .button-external-hover:hover & {
                @include button-hover;
            }
        }
    }
    &.disabled {
        pointer-events: none;
        opacity: 0.5;
    }
    &.loading {
        > *:not(.icon-a-loader) {
            color: transparent;
        }
    }
    &.f-full {
        &:not(.np) {
            width: 100%;
        }
    }
    &.f-fit {
        &:not(.np) {
            min-width: var(--button-external-width, var(--width));
            padding: 0 var(--button-external-padding, calc(var(--height) - #{rem(12)}));
        }
    }
    &.cr-full {
        border-radius: var(--height);
    }
    &.cr-smooth {
        border-radius: var(--border-radius);
    }
    &.c-brand {
        @include over(hero, brand);
    }
    &.c-accent {
        background: c(s5);
        color: c(brand);
        @include over(hero, s5);
        @mixin button-hover {
            background: c(s5);
            color: c(brand-accent);
        }
        @include hover('.popover-opened') {
            @include button-hover;
        }
        @include media-hover {
            .button-external-hover:hover & {
                @include button-hover;
            }
        }
        &.c-danger {
            background: c(error, 0.1);
            color: c(error);
            @mixin button-hover {
                background: c(error, 0.15);
                color: c(error-hover);
            }
            @include hover('.popover-opened') {
                @include button-hover;
            }
            @include media-hover {
                .button-external-hover:hover & {
                    @include button-hover;
                }
            }
        }
    }
    &.c-contrast {
        color: inherit;
    }
    &.c-contrast, &.c-grayscale {
        @include over(hero, b);
        @mixin button-hover {
            background: c(b);
            color: c(brand);
        }
        @include hover('.popover-opened') {
            @include button-hover;
        }
        @include media-hover {
            .button-external-hover:hover & {
                @include button-hover;
            }
        }
    }
    &.c-grayscale {
        @mixin button-hover {
            background: c(s5);
            color: c(brand-accent);
        }
        &.force-focus {
            &:focus {
                &:not(:active) {
                    box-shadow: 0 0 0 rem(2) c(brand);
                }
            }
        }
        @include hover('.popover-opened') {
            @include button-hover;
        }
        @include media-hover {
            .button-external-hover:hover & {
                @include button-hover;
            }
        }
    }
    &.c-color {
        color: c(hero);
        background: c(b);
        @mixin button-hover {
            background: c(b);
            color: c(hero-hover);
        }
        @include hover('.popover-opened') {
            @include button-hover;
        }
        @include media-hover {
            .button-external-hover:hover & {
                @include button-hover;
            }
        }
    }
    &.c-light {
        color: c(brand);
        background: c(s5, 0);
        @mixin button-hover {
            background: c(s5, 1);
            color: c(brand-hover);
        }
        @include hover('.popover-opened') {
            @include button-hover;
        }
        @include media-hover {
            .button-external-hover:hover & {
                @include button-hover;
            }
        }
    }
    &.c-bold {
        background: c(f);
        color: sc(f);
        @include darkMode {
            color: black;
            background: #f3f3f5;
        }
        @mixin button-hover {
            color: sc(f);
            background: black;
            @include darkMode {
                color: black;
                background-color: white;
            }
        }
        @include hover('.popover-opened') {
            @include button-hover;
        }
        @include media-hover {
            .button-external-hover:hover & {
                @include button-hover;
            }
        }
    }
    &.c-bold-invert {
        color: black;
        background: #f3f3f5;
        @include darkMode {
            color: sc(f);
            background: c(f);
        }
        @mixin button-hover {
            color: black;
            background: white;
            @include darkMode {
                color: sc(f);
                background: black;
            }
        }
        @include hover('.popover-opened') {
            @include button-hover;
        }
        @include media-hover {
            .button-external-hover:hover & {
                @include button-hover;
            }
        }
    }
    &.a-outline {
        border: rem(1) solid c(hero);
        background: none;
        color: c(hero);
        &.force-focus {
            &:focus {
                &:not(:active) {
                    box-shadow: var(--box-shadow), 0 0 0 rem(1) c(hero);
                }
            }
        }
        @include hover {
            background: none;
        }
        &.c-bold {
            color: c(f);
            background: none;
            border-color: c(f);
            box-shadow: var(--box-shadow), inset 0 0 0 0 c(f);
            @include hover {
                color: c(f);
                box-shadow: var(--box-shadow), inset 0 0 0 rem(1) c(f);
            }
            @include darkMode {
                color: #f3f3f5;
                background: none;
                border-color: #f3f3f5;
                box-shadow: var(--box-shadow), inset 0 0 0 0 white;
                @include hover {
                    color: #f3f3f5;
                    border-color: white;
                    background: none;
                    box-shadow: var(--box-shadow), inset 0 0 0 rem(1) white;
                }
            }
        }
    }
    &.shadow {
        --box-shadow: var(--box-shadow-size, #{$pureMesmerizingShadow});
        @mixin button-hover {
            --box-shadow: var(--box-shadow-size-hover, #{$pureMesmerizingShadowHover});
        }
        @include hover {
            @include button-hover;
        }
        @include media-hover {
            .button-external-hover:hover & {
                @include button-hover;
            }
        }
    }
    .icon-a-loader {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
    }
}
.button-addon-holder {
    position: absolute;
    left: rem(2);
    top: rem(2);
    bottom: rem(2);
    width: calc(var(--height) - #{rem(4)});
    display: flex;
    align-items: center;
    justify-content: center;
    background: sc(hero);
    border-radius: rem(8);
    svg {
        width: rem(20);
        height: rem(20);
        transition: 400ms transform;
    }
}
