@import 'src/assets/scss/magic';
.menu-item {
    display: flex;
    align-items: center;
    width: 100%;
    overflow: hidden;
    height: rem(50);
    padding: 0 rem(24);
    transition: 400ms color, 400ms opacity;
    font: f(14) $f;
    cursor: pointer;
    position: relative;
    &.highlighted {
        font-weight: 600;
    }
    &.disabled {
        pointer-events: none;
        opacity: 0.5;
    }
    @include hover {
        color: c(brand);
    }
    &.selected {
        color: c(brand);
        @include hover {
            color: c(brand-hover);
        }
    }
    &.danger {
        color: c(error);
        @include hover {
            color: c(error-hover);
        }
    }
}
.mi-content {
    width: 100%;
    display: flex;
    align-items: center;
    gap: rem(12);
    > li {
        display: flex;
        justify-content: flex-start;
        &.f-fit {
            flex-shrink: 0;
            min-width: rem(24);
            height: rem(24);
            justify-content: center;
            align-items: center;
            position: relative;
            &:last-child {
                justify-content: flex-end;
                gap: rem(8);
            }
        }
        &.f-auto {
            flex: auto;
        }
    }
    .icon-a-target {
        color: c(brand);
    }
}
.mi-avatar-holder {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate3d(-50%, -50%, 0);
}
