@import 'src/assets/scss/magic';
footer {
    transition: 400ms padding-bottom;
    padding-bottom: calc(env(safe-area-inset-bottom, 0px) / 2);
}
.footer-content {
    width: 100%;
    margin: rem(60) 0 rem(100);
    align-items: flex-start;
    &.grid {
        @include desktop {
            grid-template-columns: var(--gridTemplateColumns);
        }
        @include mobile {
            grid-template-columns: repeat(2, 1fr);
            margin: rem(40) 0 rem(50);
            grid-row-gap: rem(40);
        }
    }
    > li {
        display: grid;
        grid-template-columns: 100%;
        grid-row-gap: rem(30);
        @include mobile {
            grid-row-gap: rem(20);
            &:last-child {
                grid-column: span 2;
            }
        }
    }
}
.f-nav-title {
    font: 700 f(18) $f;
    @include mobile {
        font: 700 f(16) $f;
    }
}
.f-nav {
    display: grid;
    grid-template-columns: 100%;
    grid-row-gap: rem(20);
    font: rem(14) $f;
    color: sc(b, 0.8);
    @include mobile {
        grid-row-gap: rem(16);
    }
    > li {
        display: flex;
    }
    a, .cursor-pointer {
        display: block;
        color: sc(b, 0.8);
        transition: 400ms color;
        @include hover {
            color: c(brand);
        }
    }
}
.footer-form-holder {
    width: 100%;
    min-height: var(--ui-kit-extra-big-height);
    @include mobile {
        min-height: var(--ui-kit-big-height);
    }
}
.social-links-holder {
    display: flex;
    gap: rem(20);
    min-height: rem(44);
    @include mobile {
        justify-content: center;
    }
}
.social-links {
    display: flex;
    a {
        display: flex;
        justify-content: center;
        align-items: center;
        width: rem(42);
        height: rem(42);
        box-shadow: 0 rem(2) rem(4) 0 sc(b, 0.2);
        border-radius: 100%;
        font-size: rem(24);
        transition: transform 400ms, color 400ms, box-shadow 400ms;
        background: c(b);
        @include hover-not-active {
            box-shadow: 0 rem(6) rem(20) rem(-6) sc(b, 0.4);
            transform: translateY(rem(-4));
        }
        &.icon-facebook {
            $color: #4968ad;
            color: $color;
            @include hover-not-active {
                color: saturate($color, 30);
            }
        }
        &.icon-twitter {
            $color: #4ba0eb;
            color: $color;
            @include hover-not-active {
                color: saturate($color, 30);
            }
        }
        &.icon-youtube {
            $color: #f61c0d;
            color: $color;
            @include hover-not-active {
                color: saturate($color, 30);
            }
        }
        ~ a {
            margin-inline-start: rem(50);
        }
    }
}
.copyright-holder {
    display: flex;
    justify-content: center;
    align-items: center;
    text-align: center;
    padding: rem(40) 0;
    @include mobile {
        padding: rem(30) 0;
    }
}
.powered-by-holder {
    display: flex;
    flex-direction: column;
    font: 700 f(10) $f;
    gap: rem(10);
    letter-spacing: rem(2);
    text-transform: uppercase;
    color: sc(b, 0.8);
}
