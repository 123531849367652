@import 'src/assets/scss/magic';
.tabs-holder {
    width: 100%;
    overflow: hidden;
    display: grid;
    grid-template-columns: 100%;
    grid-row-gap: rem(20);
    &:has(table) {
        overflow: visible;
    }
    @include mobile {
        .guide & {
            width: calc(100% + (var(--guide-padding) * 2));
            margin: 0 calc(-1 * var(--guide-padding)) calc(-1 * var(--guide-padding));
        }
        .grid > & {
            margin-bottom: 0;
        }
    }
}
.tab-head {
    width: 100%;
    display: flex;
    position: relative;
    @include mobile {
        .guide & {
            width: calc(100% - (var(--guide-padding) * 2));
            margin: 0 var(--guide-padding);
        }
    }
    &:before, .box {
        content: '';
        display: block;
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        pointer-events: none;
        border: rem(1) solid sc(b, 0.05);
        border-radius: rem(60);
    }
    > li {
        --font-size: #{rem(13)};
        --line-height: #{rem(19)};
        display: flex;
        justify-content: center;
        align-items: center;
        overflow: hidden;
        text-align: center;
        padding: 0 rem(20);
        cursor: pointer;
        transition: 400ms color;
        height: rem(40);
        font: 500 var(--font-size) / var(--line-height) $f;
        @include mobile {
            padding: 0;
        }
        @include hover-active {
            color: c(brand);
        }
        &.active {
            pointer-events: none;
        }
        &.box {
            border-color: c(brand);
            transition: 400ms transform;
        }
    }
}
.tab-title-holder {
    display: flex;
    align-items: center;
    gap: rem(8);
    position: relative;
    transition: 300ms gap;
    &.no-gap {
        gap: 0;
        .badge {
            max-width: 0;
        }
    }
    @include desktop {
        overflow: hidden;
    }
}
.tab-body {
    overflow: hidden;
    transition: 400ms height;
    will-change: height;
    width: 100%;
    &:has(table) {
        overflow: visible;
    }
    @include mobile {
        .guide & {
            padding: 0 var(--guide-padding);
            > li {
                padding: 0 0 var(--guide-padding);
            }
        }
    }
}
