@import "src/assets/scss/magic";
.intro-for-advertisers-holder {
    display: flex;
    align-items: center;
    width: 100%;
    justify-content: space-between;
    @include mobile {
        width: calc(100% + (var(--guide-padding) * 2));
        margin: rem(-60) calc(-1 * var(--guide-padding)) 0;
        padding: rem(60) var(--guide-padding) 0;
        flex-direction: column-reverse;
        overflow: hidden;
    }
}
.intro-for-advertisers-content {
    display: flex;
    align-items: flex-start;
    flex-direction: column;
    @include mobile {
        text-align: center;
        align-items: center;
    }
    h1 {
        font: 900 rem(60) / 1.4 $f;
        @include mobile {
            font-size: rem(32);
        }
        strong {
            font-weight: inherit;
            color: mix(#97E632, #00ADAC);
            @supports (-webkit-background-clip: text) {
                background: linear-gradient(90deg, #97E632 0%, #4EDF9B 69.33%, #00ADAC 183.96%);
                -webkit-background-clip: text;
                color: transparent;
            }
        }
    }
    p {
        max-width: rem(500);
        font: 300 rem(20) / 1.6 $f;
        text-wrap: balance;
        color: sc(b, 0.8);
        margin: rem(20) 0 rem(40);
        @include mobile {
            font-size: rem(16);
            margin: rem(24) 0;
        }
    }
}
