@import "src/assets/scss/magic";
.dropdown-mobile-header {
    padding: 0 rem(20);
    height: rem(54);
    display: flex;
    align-items: center;
    font: 500 f(14) $f;
    color: c(brand);
    box-shadow: inset 0 rem(-1) 0 c(s5);
}
