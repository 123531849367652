@import 'src/assets/scss/magic';
.avatar-holder {
    width: rem(50);
    cursor: pointer;
    position: relative;
    figure {
        padding: 100% 0 0;
        position: relative;
        background: c(s5) no-repeat 50% 50% / cover;
        border-radius: 100%;
        box-shadow: 0 0 0 0 c(s5);
        transition: 400ms box-shadow, 400ms background;
        color: c(brand);
    }
    @include hover('.popover-opened') {
        figure {
            box-shadow: 0 0 0 rem(4) c(s5);
            background-color: c(b);
        }
    }
    &.s-small {
        width: rem(30);
    }
    &.s-medium {
        width: rem(44);
    }
    &.s-extra-big {
        width: rem(100);
    }
    &.s-extra-large {
        width: rem(130);
    }
    label {
        cursor: pointer;
        display: flex;
        align-items: flex-end;
        justify-content: flex-end;
        input {
            pointer-events: none;
        }
    }
}
.avatar-badge {
    position: absolute;
    top: rem(3);
    right: rem(3);
    width: rem(8);
    height: rem(8);
    background: c(brand);
    border-radius: 100%;
    z-index: 2;
    box-shadow: 0 0 0 rem(3) c(b);
    transition: 400ms transform;
    &:not(.active) {
        transform: scale(0);
    }
}
.avatar-placeholder-icon {
    display: flex;
    justify-content: center;
    align-items: center;
}
.a-edit-button-holder {
    transform: translate3d(12%, 12%, 0);
    transition: 400ms opacity, 400ms transform;
    box-shadow: 0 0 0 rem(3) c(b);
    border-radius: 100%;
    &:not(.active) {
        transform: translate3d(12%, 12%, 0) scale(0.9);
        opacity: 0;
        pointer-events: none;
    }
}
.avatar-uploading-states {
    > li {
        transition: 400ms opacity, 400ms transform;
        &:not(.active) {
            transform: scale(0.9);
            opacity: 0;
            pointer-events: none;
        }
    }
}
.avatar-spinner {
    width: 100%;
    height: 100%;
    position: relative;
    &:before {
        $gap: 10;
        content: '';
        display: block;
        position: absolute;
        top: rem(-$gap);
        right: rem(-$gap);
        bottom: rem(-$gap);
        left: rem(-$gap);
        border: rem(1) dashed c(brand);
        border-radius: 100%;
    }
    &.active {
        &:before {
            animation: rotate 200ms infinite linear;
        }
    }
}
.avatar-drop-here {
    background: c(b);
    border-radius: 100%;
    border: rem(1) dashed c(brand);
    background: c(b);
    display: flex;
    justify-content: center;
    align-items: center;
    color: c(brand);
}
