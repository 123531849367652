@import "src/assets/scss/magic";
.partnership-benefits-head {
    display: grid;
    width: 100%;
    grid-template-columns: auto 1fr auto;
    gap: rem(24);
    align-items: center;
    @include mobile {
        grid-template-columns: 100%;
    }
    h2 {
        font: 200 rem(50) / 1.3 $f;
        @include mobile {
            font-size: rem(30);
            line-height: 1.5;
            text-align: center;
        }
        strong {
            font-weight: 900;
            color: mix(#5560FF, #0D91EF);
            @supports (-webkit-background-clip: text) {
                background: linear-gradient(274.81deg, #5560FF, #0D91EF);
                -webkit-background-clip: text;
                color: transparent;
            }
        }
    }
}
.partnership-benefit-card {
    padding: rem(30) rem(30) rem(40);
    background: c(b);
    box-shadow: $pureMesmerizingShadow;
    border-radius: rem(24);
    @include mobile {
        padding: rem(24) rem(24) rem(30);
    }
    svg {
        color: #3675F8;
    }
    h3 {
        font: rem(20) / 1.6 $f;
        @include mobile {
            font-size: rem(18);
        }
    }
    p {
        font: 300 rem(14) / 1.8 $f;
    }
}
