@import "src/assets/scss/magic";
.info-layer-bottom {
    left: 50%;
    position: fixed;
    bottom: rem(20);
    transform: translate3d(-50%, 0, 0);
    background: black;
    color: white;
    z-index: 9999999999;
    font: 500 f(12, 18) $f;
    text-align: center;
    padding: rem(6) rem(36);
    border-radius: rem(100);
    pointer-events: none;
    white-space: nowrap;
    opacity: 0;
    html:has([href*="https://insights.hotjar.com"]) & {
        opacity: 1;
    }
    &:empty {
        display: none;
    }
    span {
        font-weight: 600;
    }
}

