@import 'src/assets/scss/magic';
.radio-holder {
    display: flex;
    align-items: center;
    position: relative;
    cursor: pointer;
    line-height: rem(22);
    input {
        position: absolute;
        top: 0;
        left: 0;
        opacity: 0;
        pointer-events: none;
        &:checked ~ small {
            border-color: c(brand);
        }
        &:not(:checked) ~ small {
            &:before {
                transform: scale(0);
            }
        }
    }
    small {
        border: rem(1) solid var(--cr-border-color-unchecked, #{sc(b, 0.14)});
        border-radius: 100%;
        display: flex;
        justify-content: center;
        align-items: center;
        width: rem(18);
        height: rem(18);
        transition: border-color 400ms;
        &:before {
            content: '';
            display: block;
            width: rem(8);
            height: rem(8);
            transition: 400ms transform;
            background: c(brand);
            border-radius: 100%;
        }
    }
    @media (hover: hover) {
        &:hover, label:hover & {
            --cr-border-color-unchecked: #{c(brand)};
        }
    }
    p {
        margin-inline-start: rem(14);
    }
}
