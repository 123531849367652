@import "src/assets/scss/magic";
.linked-screen-notice-holder {
    width: 100vw;
    max-width: rem(460);
    display: grid;
    grid-template-columns: 100%;
    grid-row-gap: rem(12);
    white-space: normal;
    text-align: left;
    @include mobile {
        width: 100%;
    }
    .tooltip & {
        padding: rem(8) 0;
    }
    .modal-content & {
        grid-row-gap: rem(15);
        margin-bottom: rem(-15);
        @include mobile {
            margin-bottom: rem(-9);
        }
    }
}
.linked-screen-notice-head {
    display: grid;
    align-items: center;
    gap: rem(12);
    grid-template-columns: auto 1fr;
    @include mobile {
        display: flex;
        flex-direction: column;
        text-align: center;
    }
}
.linked-screen-notice-head-icon {
    width: rem(68);
    height: rem(68);
    border-radius: rem(60);
    display: flex;
    align-items: center;
    justify-content: center;
    background: c(success, 0.05);
    color: c(success);
}
.tooltip-holder {
    .tooltip {
        &:has(.linked-screen-notice-holder) {
            position: relative;
            &:before {
                @include desktop {
                    content: '';
                    position: absolute;
                    top: 100%;
                    left: 50%;
                    width: rem(16);
                    height: rem(16);
                    background: c(b);
                    border-radius: rem(3);
                    transform: translate3d(-50%, -70%, 0) rotate(45deg);
                }
            }
        }
    }
    &[data-popper-placement*="bottom"] {
        .tooltip {
            &:has(.linked-screen-notice-holder) {
                &:before {
                    top: auto;
                    bottom: 100%;
                    transform: translate3d(-50%, 70%, 0) rotate(45deg);
                }
            }
        }
    }
}
.linked-screen-includes {
    gap: rem(20);
    display: flex;
    align-items: flex-start;
    justify-content: flex-start;
}
.linked-screen-item {
    display: grid;
    align-items: center;
    grid-template-columns: auto 1fr;
    gap: rem(6);
    position: relative;
    .loader-spinner {
        display: none;
    }
}
.linked-screen-item-image {
    width: rem(40);
    height: rem(40);
    > * {
        border-radius: rem(8) !important;
    }
}
.linked-screen-meta {
    gap: rem(2);
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    .skeleton-wave {
        --line-height: #{rem(18)};
        --font-size: #{rem(12)};
        width: rem(30);
    }
    .linked-screen-title {
        font: 600 f(10) $f;
        .skeleton-wave {
            width: rem(50);
            --font-size: #{rem(10)};
            --line-height: #{rem(14)};
        }
    }
    a {
        color: sc(b);
        @include hover {
            color: c(brand);
        }
    }
}
.linked-screen-notice-headings {
    display: grid;
    grid-template-columns: 100%;
    gap: rem(8);
}
.linked-screen-notice-title {
    font: 600 f(12, 20) $f;
    color: sc(b);
}
.linked-screen-notice-sub-title {
    font: f(12) $f;
    color: sc(b, 0.6);
}
.linked-screen-footnote-holder {
    display: flex;
    align-items: center;
    justify-content: space-between;
    @include mobile {
        gap: rem(8);
        flex-direction: column;
    }
    > * {
        color: sc(b, 0.6);
        &:only-child {
            font: 600 f(10) $f;
        }
        &:not(:only-child) {
            font: f(12) $f;
        }
    }
    .ch-label {
        margin-inline-start: rem(8);
    }
}
.linked-screen-footnote {
    text-align: right;
    padding: rem(2) 0 0;
}
