@import "src/assets/scss/magic";
.inline-up-sale-holder {
    pointer-events: all;
    color: c(b);
    background: linear-gradient(0deg, rgba(7, 41, 71, 0.94), rgba(7, 41, 71, 0.98)), linear-gradient(282.74deg, rgba(0, 0, 0, 0) 17.94%, #33FF00 107.7%), linear-gradient(101.79deg, #C8FF00 0%, rgba(243, 63, 180, 0.9) 51.5%, #1489D7 100%);
    position: relative;
    @include darkMode {
        background: linear-gradient(0deg, rgba(255, 255, 255, 0.94), rgba(255, 255, 255, 0.94)), linear-gradient(282.74deg, rgba(0, 0, 0, 0) 17.94%, #33FF00 107.7%), linear-gradient(101.79deg, #C8FF00 0%, rgba(243, 63, 180, 0.9) 51.5%, #1489D7 100%);
    }
    button {
        width: rem(28);
        height: rem(28);
        border-radius: rem(28);
        display: flex;
        align-items: center;
        justify-content: center;
        cursor: pointer;
        transition: background-color 300ms, color 300ms;
        background: rgba(#000000, 0.4);
        color: rgba(#B9DDFF, 0.6);
        @include hover {
            color: rgba(#B9DDFF, 0.8);
            background: rgba(#000000, 0.6);
        }
        @include darkMode {
            background: rgba(#0b313c, 0.2);
            color: #0b313c;
            @include hover {
                color: #021a20;
                background: rgba(#0b313c, 0.3);
            }
        }
    }
    &.a-row, &.a-column {
        display: grid;
        align-items: center;
        grid-template-columns: 1fr auto;
    }
    &.a-row {
        padding: rem(10) rem(16);
        border-radius: rem(30);
        .inline-up-sale-new-icon {
            font-size: rem(9);
        }
    }
    &.a-column {
        padding: rem(16);
        border-radius: rem(24);
        align-items: flex-start;
    }
    .inline-up-sale-title {
        font: 300 rem(20) / 1.5 $f;
    }
    &.a-compact {
        padding: rem(16);
        border-radius: rem(24);
        box-shadow: 0 0 0 rem(2) rgba(white, 0.2);
        @include darkMode {
            box-shadow: 0 0 0 rem(2) rgba(black, 0.2);
        }
        .inline-up-sale-title {
            font-size: rem(16);
        }
        button {
            position: absolute;
            top: rem(-8);
            right: rem(-8);
            box-shadow: $pureMesmerizingShadowCenter;
            background: c(b);
            border-radius: rem(10);
            color: sc(b, 0.5);
            @include hover {
                color: sc(b, 0.8);
            }
        }
    }
    small {
        background: c(b, 0.3);
        width: rem(12);
        height: rem(1.5);
        border-radius: rem(2);
        display: block;
    }
    p {
        font: 400 rem(13) / 1.6 $f;
        color: c(b, 0.8);
        a {
            display: inline-flex;
            align-items: center;
            gap: rem(4);
            vertical-align: baseline;
            color: c(b);
            border-bottom: rem(1) dashed c(b, 0.4);
            transition: 250ms border-bottom-color;
            @include hover {
                border-bottom-color: c(b, 0);
            }
        }
    }
}
.inline-up-sale-new-icon {
    color: #8BDBF6;
    text-transform: uppercase;
    font: 700 f(6) $f;
    @include darkMode {
        color: #0b313c;
    }
    svg {
        path {
            fill: rgba(#000000, 0.4);
            stroke: none;
            @include darkMode {
                fill: rgba(#0b313c, 0.2);
            }
            &:nth-child(2) {
                display: none;
            }
        }
    }
}
