@import 'src/assets/scss/magic';
.text-transition-c {
    line-height: rem(22);
    .button & {
        height: rem(22) !important;
    }
}
.text-transition-holder {
    &.f-full {
        &, > * {
            width: 100% !important;
        }
        > * {
            &:only-child {
                position: relative !important;
            }
        }
    }
    &.f-fit {
        white-space: nowrap;
    }
}
