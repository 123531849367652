@import 'src/assets/scss/magic';
.option-holder {
    --height: #{rem(52)};
    --padding: #{rem(26)};
    --gap: #{rem(12)};
    --font-size: #{rem(14)};
    width: 100%;
    min-height: var(--height);
    display: grid;
    grid-template-columns: 100%;
    font: var(--font-size) / 1.25 $f;
    padding: 0 var(--padding);
    grid-column-gap: var(--gap);
    align-items: center;
    color: sc(b);
    transition: 400ms color, 400ms background;
    cursor: pointer;
    &.has-ap {
        padding: 0 calc(var(--padding) + #{rem(4)});
    }
    &.s-big {
        --gap: #{rem(24)};
        --height: #{rem(64)};
        --padding: #{rem(24)};
        --font-size: #{rem(16)};
    }
    &.s-medium {
        --gap: #{rem(12)};
        --height: #{rem(50)};
        --padding: #{rem(20)};
        --font-size: #{rem(14)};
    }
    &.c-start {
        grid-template-columns: auto 1fr;
    }
    &.c-end {
        grid-template-columns: 1fr auto;
    }
    &.c-start.c-end {
        grid-template-columns: auto 1fr auto;
    }
    > li {
        display: flex;
        flex-direction: column;
        overflow: hidden;
        &.o-c-holder {
            flex-direction: row;
            align-items: center;
            justify-content: center;
            gap: var(--gap, #{rem(8)});
        }
        > .o-information {
            font-size: rem(12);
            margin: rem(2) 0 0;
            opacity: 0.7;
        }
    }
    .option-icon {
        color: sc(b, 0.48);
        transition: 400ms color;
    }
    @mixin hoverStyle {
        --cr-border-color-unchecked: #{c(brand)};
        &:not(.has-cr) {
            background: c(s5, 0.7);
            color: c(brand);
        }
        .option-icon {
            color: c(brand);
        }
    }
    @include hover('.highlighted') {
        @include hoverStyle;
    }
    .ant-select-item-option-active & {
        @include hoverStyle;
    }
    &.highlighted:not(:hover), .ant-select-item-option-active &:not(:hover) {
        &:not(.has-cr) {
            background: c(s5);
        }
    }
    &.selected {
        .option-icon {
            color: c(brand, 0.7 );
        }
        &:not(.has-cr) {
            color: c(brand);
        }
    }
    &:not(.selected) {
        .option-check {
            opacity: 0;
            transform: scale(0);
        }
    }
    ::first-letter {
        text-transform: uppercase;
        user-select: none;
    }
}
.option-check {
    color: c(brand);
    transition: 400ms opacity, 400ms transform;
}
