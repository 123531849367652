@import "src/assets/scss/magic";
.i-label-inset-card {
    padding: rem(20);
    width: 100%;
    position: relative;
    height: rem(140);
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: space-between;
    --font-size: #{rem(14)};
    --line-height: #{rem(20)};
    i {
        color: sc(b, 0.4);
        transition: 400ms color;
    }
    p {
        text-wrap: balance;
        font-weight: 500;
        font-size: var(--font-size);
        line-height: var(--line-height);
    }
}
.card-holder {
    @include hover {
        .i-label-inset-card {
            i {
                color: c(brand);
            }
        }
    }
}
