@import 'src/assets/scss/magic';
.screen-featured-holder {
    display: flex;
    width: 100%;
    --font-size: #{rem(14)};
    --line-height: #{rem(24)};
    flex-direction: column;
    @include mobile {
        scroll-snap-align: center;
    }
    figure {
        width: 100%;
        position: relative;
        box-shadow: $pureMesmerizingShadow;
        transition: 400ms box-shadow, 400ms transform;
        &:has(.not-loaded) {
            --pureMesmerizingShadow: 0;
        }
    }
    h3 {
        --font-size: #{rem(16)};
        --line-height: #{rem(22)};
        font: 600 var(--font-size) / var(--line-height) $f;
        text-transform: capitalize;
        overflow: hidden;
        a {
            display: block;
            @include hover {
                color: c(brand);
            }
        }
    }
    .divider {
        border-top: rem(1) solid c(s5);
    }
    &.hovered {
        .screen-featured-figure-image {
            filter: saturate(1.5);
        }
    }
    figure {
        border-radius: rem(14);
        padding: per(375, 290) 0 0;
    }
    h3 {
        margin: rem($guidePadding) 0 0;
    }
    .divider {
        margin: rem(12) 0;
    }
    @include hover {
        figure {
            box-shadow: $pureMesmerizingShadowHover;
            transform: translateY(rem(-6));
        }
        .screen-featured-figure-image {
            filter: saturate(1.5);
        }
        h3 {
            color: c(brand);
        }
    }
    &.skeleton {
        figure {
            box-shadow: none;
        }
    }
}
.screen-featured-figure-image {
    background: no-repeat 50% 50% / cover;
    transition: 400ms filter;
    border-radius: inherit;
    @include media-hover {
        figure:hover & {
            filter: saturate(1.5);
        }
    }
    picture, img {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
    }
}
.screen-featured-rating-holder {
    position: absolute;
    top: rem($guidePadding);
    right: rem($guidePadding);
    background: c(b);
    border-radius: rem(36);
    padding: rem(2) rem(10);
    box-shadow: $pureMesmerizingShadow;
}
.screen-featured-description {
    margin: rem(4) 0 0;
    font: var(--font-size) / var(--line-height) $f;
}
.screen-featured-additional-information {
    width: 100%;
    display: grid;
    grid-template-columns: 100%;
    grid-row-gap: rem(6);
    text-transform: capitalize;
    font: 600 var(--font-size) / var(--line-height) $f;
    span {
        color: sc(b);
        vertical-align: baseline;
    }
    strong {
        color: c(brand);
        font-size: rem(16);
        vertical-align: baseline;
    }
}
