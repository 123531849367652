@import 'src/assets/scss/magic';
.icon {
    --size: var(--icon-external-size, #{rem(24)});
    width: var(--size);
    height: var(--size);
    font-size: var(--size);
    display: flex;
    align-items: center;
    justify-content: center;
    transition: 400ms transform;
    cursor: pointer;
    flex-shrink: 0;
    position: relative;
    &:after {
        content: "";
        position: absolute;
        top: 50%;
        left: 50%;
        width: rem(1.5);
        height: 120%;
        box-shadow: 0 0 0 rem(1) c(b);
        background: currentColor;
        opacity: 0;
        transform: translate3d(-50%, -50%, 0) rotate(40deg) scale(0);
        transition: 400ms opacity, 400ms transform;
    }
    .slash & {
        &:after {
            opacity: 1;
            transform: translate3d(-50%, -50%, 0) rotate(40deg) scale(1);
        }
    }
    &.currency {
        font: var(--size) / 1 $f;
    }
    &.skeleton {
        border-radius: 100% !important;
        &:before {
            content: '';
        }
    }
    &.icon-a-loader {
        pointer-events: none;
        svg {
            width: var(--size, 100%);
            height: var(--size, 100%);
            animation: 300ms rotate linear infinite;
            @keyframes rotate {
                to {
                    transform: rotate(360deg);
                }
            }
        }
        &:before {
            display: none;
        }
    }
    &.multi-color {
        &:before {
            display: none;
        }
        span {
            display: block;
            color: inherit;
            &:before {
                color: inherit;
            }
        }
    }
    .i-currency {
        display: block;
    }
    .i-today {
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
        position: absolute;
        display: flex;
        justify-content: center;
        align-items: center;
        font: 700 calc(var(--size) / 2) / 1 $f;
        &.indent {
            left: rem(-1);
        }
    }
    &.icon-instagram {
        --gradient-lavender: 211, 0, 197;
        --gradient-orange: 255, 122, 0;
        --gradient-pink: 255, 1, 105;
        --gradient-purple: 118, 56, 250;
        --gradient-red: 255, 48, 64;
        --gradient-yellow: 255, 214, 0;
        color: rgb(var(--gradient-pink));
        @supports (-webkit-background-clip: text) {
            background: -webkit-linear-gradient(96deg, rgb(var(--gradient-yellow)) -48.57%, rgb(var(--gradient-orange)), rgb(var(--gradient-pink)) 38.42%, rgb(var(--gradient-purple)) 90.44%);
            -webkit-background-clip: text;
            color: transparent;
        }
    }
}
.i-flag {
    width: 100%;
    height: 100%;
    display: block;
    border-radius: 100%;
    background: no-repeat 50% 50% / cover;
    &.ft-arm {
        background-image: url('./media/arm.svg');
    }
    &.ft-rus {
        background-image: url('./media/rus.svg');
    }
    &.ft-usa {
        background-image: url('./media/usa.svg');
    }
    &.ft-geo {
        background-image: url('./media/geo.svg');
    }
}
