@import 'src/assets/scss/magic';
.modal-holder {
    position: fixed;
    top: calc(var(--header-height) + env(safe-area-inset-top, 0px));
    left: 0;
    right: 0;
    height: calc(var(--vh) - var(--header-height) - env(safe-area-inset-top, 0px));
    z-index: 200;
    overflow-y: auto;
    overflow-x: hidden;
    padding: rem(30) rem(30) calc(var(--header-height) + #{rem(30)});
    will-change: scroll-position;
    overscroll-behavior: none none;
    display: flex;
    align-items: flex-start;
    transition: 400ms height, 400ms top, 400ms padding;
    -webkit-overflow-scrolling: touch;
    --sticky-actions: calc((var(--header-height) + #{rem(30)}) * -1);
    @include mobile {
        top: auto;
        bottom: 0;
        z-index: 100;
        height: calc(var(--vh) + #{rem(500)});
        padding: calc(#{rem(10 + 500)} + env(safe-area-inset-top, 0px)) rem(10) calc(#{rem(10)} + env(safe-area-inset-bottom, 0));
        --sticky-actions: calc((#{rem(10)} + env(safe-area-inset-bottom, 0)) * -1);
    }
    &.full-cover {
        --header-height: 0px;
        z-index: 1000;
    }
}
.modal {
    background: c(b);
    position: relative;
    width: auto;
    box-shadow: 0 rem(20) rem(30) rgba(#000000, 0.1);
    margin: auto;
    opacity: 0;
    transition: 500ms width, 500ms max-width;
    border-radius: rem(36);
    &:has(.authentication-container), &:has(.pt-auth) {
        box-shadow: 0 rem(40) rem(40) rem(-10) rgba(#000000, 0.1);
        .modal-contents-holder {
            &:before {
                content: none;
            }
        }
        @include desktop {
            border-radius: rem(48);
        }
        @include mobile {
            border-radius: rem(36);
        }
    }
    @include mobile {
        width: 100%;
        border-radius: rem(24);
    }
    &.f-full {
        width: 100%;
    }
}
.modal-contents-holder {
    padding: 0 rem(40);
    @include mobile {
        padding: 0 rem(20);
        &:before {
            left: 0;
            content: "";
            z-index: 1;
            top: rem(-24);
            display: block;
            height: rem(36);
            position: sticky;
            background: c(b);
            margin-top: rem(24);
            margin-left: rem(-20);
            width: calc(100% + #{rem(40)});
            margin-bottom: calc(#{rem(-24)} - #{rem(36)});
        }
        &:not(:has(.modal-head)) {
            &:before {
                display: none;
            }
        }
    }
}
.modal-backdrop {
    position: fixed;
    top: calc(var(--header-height) + env(safe-area-inset-top, 0px));
    left: 0;
    height: calc(var(--vh) - var(--header-height) - env(safe-area-inset-top, 0px));
    transition: 400ms height, 400ms top;
    right: 0;
    backdrop-filter: blur(rem(10));
    background: c(b, 0.2);
    @include darkMode {
        background: rgba(black, 0.2);
        backdrop-filter: blur(rem(10));
    }
    @include mobile {
        bottom: 0;
        top: auto;
        height: calc(var(--vh) + #{rem(500)});
    }
}
.modal-head {
    font: 600 rem(15) / rem(20) $f;
    display: grid;
    grid-template-columns: minmax(#{rem(32)}, 1fr) auto 1fr;
    text-transform: capitalize;
    height: rem(80);
    align-items: center;
    @include mobile {
        position: sticky;
        top: rem(-10);
        z-index: 1;
        background: c(b);
        width: calc(100% + #{rem(40)});
        margin: 0 rem(-20);
        padding: 0 rem(20);
        border-radius: rem(24) rem(24) 0 0;
    }
    .modal-head-title {
        text-wrap: balance;
    }
    .ah-start & {
        grid-template-columns: auto 1fr;
        height: rem(86);
        .modal-head-title {
            font: rem(16) / rem(22) $f;
            text-align: left;
        }
        .modal-head-sub-title {
            margin: rem(3) 0 0;
            font: rem(11) / rem(20) $f;
            color: sc(b, 0.7);
            text-align: left;
        }
    }
    @include mobile {
        height: rem(76);
    }
    &.pt {
        padding-top: rem(20);
    }
    > li {
        display: flex;
        align-items: flex-start;
        text-align: center;
        flex-direction: column;
        &:last-child {
            align-items: flex-end;
        }
    }
}
.modal-close-holder {
    position: absolute;
    top: rem(40);
    right: rem(40);
    z-index: 2;
    @include mobile {
        top: rem(20);
        right: rem(20);
    }
}
.modal-content {
    position: relative;
    z-index: 0;
    padding: rem(30) 0;
    @include mobile {
        padding: rem(24) 0;
    }
    &:has(.screen-page) {
        padding: rem(10) 0 rem(20);
        @include mobile {
            padding: rem(12) 0 rem(24);
        }
        &:last-child {
            padding-bottom: rem(40);
            @include mobile {
                padding-bottom: rem(20);
            }
        }
    }
}
.modal-message-loader {
    height: rem(260);
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    position: relative;
}
.modal-message-holder {
    --icon-size: #{rem(80)};
    width: 100%;
    display: flex;
    align-items: center;
    font: f(14, 22) $f;
    @include mobile {
        --icon-size: #{rem(70)};
        flex-direction: column;
        align-items: center;
        justify-content: center;
        text-align: center;
    }
    &.c-error {
        > li {
            &:first-child {
                @include over(brand, error);
            }
        }
    }
    &.c-warning {
        > li {
            &:first-child {
                @include over(brand, warning);
            }
        }
    }
    &.c-success {
        > li {
            &:first-child {
                @include over(brand, success);
            }
        }
    }
    > li {
        &:first-child {
            .icon {
                border-radius: 100%;
                width: var(--icon-size);
                height: var(--icon-size);
                background: c(brand, 0.05);
                color: c(brand);
            }
        }
        @include desktop {
            padding: 0 rem(10);
            &:first-child {
                flex-shrink: 0;
            }
            &:last-child {
                flex: auto;
            }
        }
        + li {
            @include mobile {
                margin: rem(20) 0 0;
            }
        }
    }
}
.modal-message-title {
    margin: 0 0 rem(4);
    color: sc(b);
    + div {
        color: sc(b, 0.6);
    }
}
.mm-node-holder {
    margin: rem(30) 0 rem(10);
}
.modal-footer {
    height: rem(80);
    display: flex;
    justify-content: center;
    align-items: center;
    position: sticky;
    bottom: var(--sticky-actions, 0);
    pointer-events: none;
    > li {
        background: c(b, 0.7);
        backdrop-filter: blur(rem(20)) brightness(1.2);
        display: flex;
        justify-content: center;
        align-items: center;
        gap: rem(4);
        padding: rem(4);
        border-radius: rem(100);
        &:has(> *:nth-child(2)) {
            @include desktop {
                display: grid;
                grid-template-columns: repeat(2, 1fr);
            }
        }
        > * {
            pointer-events: auto;
        }
        .c-grayscale {
            background: none;
            @include hover {
                background: c(b);
            }
        }
    }
}
