@import "src/assets/scss/magic";
.flex-component {
    display: flex;
    min-width: 0;
    &.row {
        flex-direction: row;
    }
    &.column {
        flex-direction: column;
    }
    &.stretch {
        width: 100%;
    }
}
