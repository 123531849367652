@import "src/assets/scss/magic";
@import "node_modules/swiper/swiper.scss";
.slider-component-holder {
    display: flex;
    z-index: 0;
    .swiper {
        width: 100%;
        &:not(.active) {
            opacity: 0;
        }
        .swiper-slide {
            @include hover {
                img {
                    &:not(:only-child) {
                        filter: saturate(1.5);
                    }
                }
            }
        }
    }
    img {
        background: c(s5);
        transition: 500ms filter;
    }
}
.slider-c-bullets {
    position: absolute;
    bottom: rem(12);
    left: 0;
    right: 0;
    display: flex;
    align-items: center;
    justify-content: center;
    gap: rem(8);
    z-index: 1;
    pointer-events: none;
    .bullet {
        width: rem(6);
        height: rem(6);
        border-radius: 100%;
        cursor: pointer;
        position: relative;
        pointer-events: all;
        transition: 400ms background, 400ms transform;
        background: rgba(white, 0.7);
        &:only-child {
            display: none;
        }
        &:not(.active) {
            &:before {
                content: '';
                position: absolute;
                inset: rem(-6);
            }
        }
        &.active {
            transform: scale(1.5);
        }
        @include hover-active {
            background: rgba(white, 1);
        }
    }
}
.slider-navigation-buttons {
    position: absolute;
    inset: 0;
    display: flex;
    align-items: center;
    justify-content: space-between;
    z-index: 10;
    transition: 300ms padding;
    pointer-events: none;
    padding: rem(24);
    button {
        width: rem(30);
        height: rem(30);
        display: flex;
        align-items: center;
        justify-content: center;
        border-radius: rem(12);
        cursor: pointer;
        color: sc(b, 0.8);
        background: c(b, 0.9);
        backdrop-filter: blur(rem(10));
        transition: 400ms background, 400ms color, 300ms transform, 250ms opacity;
        pointer-events: all;
        transform: scale(0.5);
        opacity: 0;
        &:first-child {
            padding-right: rem(2);
        }
        &:last-child {
            padding-left: rem(2);
        }
        @include hover {
            background: c(b, 1);
            color: c(brand);
        }
        &:disabled {
            color: rgba(black, 0.2);
            background: c(b, 0.4);
            cursor: default;
        }
    }
}
.slider-navigation-buttons-hover {
    @include hover {
        .slider-navigation-buttons {
            padding: rem(6);
            button {
                transform: scale(1);
                opacity: 1;
            }
        }
    }
}
.slider-navigation-buttons-reveal {
    .slider-navigation-buttons {
        animation: 300ms slider-navigation-buttons-reveal forwards;
        @keyframes slider-navigation-buttons-reveal {
            to {
                padding: rem(6);
            }
        }
        button {
            animation: 300ms slider-navigation-button-reveal forwards;
            @keyframes slider-navigation-button-reveal {
                to {
                    transform: scale(1);
                    opacity: 1;
                }
            }
        }
    }
}
