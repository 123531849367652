@import 'src/assets/scss/magic';
.section-holder {
    width: 100%;
    display: grid;
    grid-template-columns: 100%;
    grid-row-gap: rem($gap);
    &.a-elegant {
        grid-row-gap: rem($guidePadding);
    }
}
.section-head {
    display: flex;
    align-items: center;
    justify-content: space-between;
    @include mobile {
        gap: rem(20);
        align-items: flex-start;
        flex-direction: column;
        justify-content: center;
    }
    > li {
        display: flex;
        align-items: center;
        gap: rem(16);
        @include mobile {
            width: 100%;
        }
    }
}
.section-titles-holder {
    width: 100%;
    display: grid;
    grid-template-columns: 100%;
    grid-row-gap: rem(6);
    ::first-letter {
        text-transform: uppercase;
        user-select: none;
    }
    h1, h2 {
        font: 700 f(20, 32) $f;
        @include mobile {
            font-size: rem(18);
        }
    }
    .a-elegant & {
        h1, h2 {
            font: rem(16) $f;
        }
        h3 {
            font: rem(12) $f;
            color: sc(b, 0.6);
        }
    }
}
