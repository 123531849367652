@import "src/assets/scss/magic";
.virtual-list-scroller {
    width: 100%;
    height: 100%;
    overflow-y: auto;
    overflow-x: hidden;
    flex-shrink: 0;
    &.absolute {
        position: absolute;
        top: 0;
        left: 0;
    }
    &.horizontal {
        overflow-y: hidden;
        overflow-x: auto;
        height: auto;
        &::-webkit-scrollbar {
            display: none;
        }
    }
}
.virtual-list-holder {
    position: relative;
    width: 100%;
    min-width: 100%;
    > li {
        width: 100%;
        position: absolute;
        top: 0;
        left: 0;
    }
    &.horizontal {
        overflow: hidden;
        > li {
            transition: 500ms width;
            width: calc(80vw + #{rem(10)});
            &:first-child {
                z-index: 10;
                position: relative;
            }
            &:only-child {
                width: calc(100vw - #{rem(32)});
            }
        }
    }
    &.vertical {
        .virtual-list-item {
            width: 100%;
            .po-content & {
                max-width: calc(100vw - #{rem(20)});
            }
        }
    }
}
.virtual-list-loader {
    display: flex;
    transform: scale(0.7);
}
