@import "src/assets/scss/magic";
.screen-price-holder {
    display: flex;
    align-items: center;
    min-height: rem(26);
    gap: rem(6);
    &.column {
        flex-direction: column;
        align-items: flex-start;
        gap: rem(4);
    }
    p {
        font: 500 f(12) $f;
        color: sc(b, 0.5);
    }
    number-flow-react {
        font: 500 rem(15) / 1 $f;
        color: sc(b);
        .ssr-footer & {
            color: c(brand);
        }
    }
    svg {
        flex-shrink: 0;
        width: rem(6);
        height: auto;
        path {
            fill: sc(b, 0.1);
        }
    }
}
.screen-price-switchers {
    display: flex;
    align-items: center;
    gap: rem(6);
    small {
        background: sc(b, 0.1);
        height: rem(1.5);
        width: rem(8);
        flex-shrink: 0;
        border-radius: rem(6);
        @include mobile {
            width: rem(6);
        }
    }
    button {
        display: flex;
        align-items: center;
        white-space: nowrap;
        cursor: pointer;
        font: 500 f(12) $f;
        color: sc(b, 0.5);
        transition: 300ms color;
        span {
            transition: 600ms max-width, 600ms opacity;
            position: relative;
            &:first-child {
                text-transform: uppercase;
                &:before {
                    content: '';
                    position: absolute;
                    inset: rem(-4) rem(-8);
                }
            }
            &:last-child {
                max-width: 0;
                overflow: hidden;
                opacity: 0;
            }
        }
        @include hover {
            color: c(success);
        }
        &.active {
            color: c(success);
            span {
                &:nth-child(2) {
                    opacity: 1;
                    max-width: calc(#{rem(10)} * var(--max-width));
                }
            }
        }
    }
}
.screen-price-switcher-active-hover {
    @include desktop {
        .screen-price-switchers {
            button {
                &.active {
                    color: sc(b, 0.5);
                }
            }
        }
        @include hover {
            .screen-price-switchers {
                button {
                    &.active {
                        color: c(success);
                    }
                }
            }
        }
    }
}
.screen-price-switcher-dropdown {
    display: flex;
    align-items: center;
    .popover-opened {
        svg {
            transform: rotate(-180deg);
        }
    }
}
.screen-price-switcher-d-label {
    font: 500 f(12) $f;
    display: flex;
    align-items: center;
    color: sc(b, 0.6);
    cursor: pointer;
    gap: rem(2);
    transition: 400ms color;
    &.read-only {
        pointer-events: none;
        color: sc(b, 0.6);
    }
    @include hover {
        color: c(success);
    }
    svg {
        width: rem(16);
        transition: 400ms transform;
        path {
            fill: currentColor;
        }
    }
}
