@import "src/assets/scss/magic";
.available-screens-c {
    width: 100%;
    display: flex;
    justify-content: center;
    margin: 0 0 rem(40);
    @include mobile {
        margin: 0;
        position: absolute;
        bottom: 100%;
        transform: translate3d(0, 30%, 0);
    }
}
.available-screens-holder {
    width: 100%;
    max-width: rem(508);
    padding-top: per(340, 660);
    position: relative;
    ul {
        li {
            position: absolute;
            top: var(--top);
            left: var(--left);
            width: var(--size);
            animation: bounceIn forwards var(--animation-duration, 1s) var(--animation-delay);
            opacity: 0;
            transform: scale3d(0.3, 0.3, 0.3);
            padding-top: var(--size);
        }
    }
}
.available-screen-item {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    border-radius: 100%;
    cursor: pointer;
    box-shadow: 0 0 0 rem(2) c(b, 0.1);
    background: no-repeat url("./media/screen-images.png") 0 var(--background-position-y, 0px) / 100%;
    transition: 1s transform, 1s box-shadow, 1s filter;
    @include retina {
        background-image: url("./media/screen-images@2x.png");
    }
    @include hover {
        transform: scale(1.2);
        filter: saturate(1.5);
        transition: 400ms transform, 400ms box-shadow, 400ms filter;
        box-shadow: 0 0 0 rem(2) c(b, 0.4);
    }
}
