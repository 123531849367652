@import url('https://fonts.googleapis.com/css2?family=Caveat&display=swap');
.caveat-regular {
    font-family: 'Caveat', cursive;
    font-optical-sizing: auto;
    font-weight: 400;
    font-style: normal;
    letter-spacing: -0.04em;
}
@font-face {
    font-family: 'Caveat';
    src: url('3DUnicode.woff2') format('woff2'), url('3DUnicode.woff') format('woff');
    font-weight: 400;
    font-style: normal;
    font-display: swap;
    unicode-range: U+0589, U+10A0-10FF, U+1C90-1CBA, U+1CBD-1CBF, U+205A, U+2D00-2D2F, U+2E31;
}
@font-face {
    font-family: 'Caveat';
    src: url('ArTarumianAnpuitU.woff2') format('woff2'), url('ArTarumianAnpuitU.woff') format('woff');
    font-weight: 400;
    font-style: normal;
    font-display: swap;
    unicode-range: U+0308, U+0530-058F, U+2010, U+2024, U+25CC, U+FB13-FB17;
}

