@import 'src/assets/scss/magic';
.short-rating-holder {
    display: grid;
    align-items: center;
    grid-template-columns: auto 1fr;
    color: c(rating);
    font: 700 rem(12) / rem(18) $f;
    gap: rem(4);
}
.rating-bar-holder {
    max-width: 100%;
    display: flex;
    align-items: center;
    gap: rem(12);
    font: 700 f(10) $f;
    text-transform: uppercase;
    color: sc(b, 0.41);
    min-width: 0;
    > li {
        overflow: hidden;
        display: flex;
        align-items: center;
        position: relative;
        &:first-child {
            flex-shrink: 0;
        }
        &:nth-child(2) {
            flex: auto;
        }
    }
}
.rating-bar {
    color: c(rating, 0.3);
    display: grid;
    grid-template-columns: repeat(5, 1fr);
    grid-column-gap: rem(6);
    &.a-filled {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        overflow: hidden;
        color: c(rating);
        transition: max-width 300ms;
    }
}
