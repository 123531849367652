@import 'magic';
:root {
    @each $size, $props in $uiKitSizes {
        @each $name, $value in $props {
            --ui-kit-#{$size}-#{$name}: #{rem($value)};
        }
    }
    --guide-padding: #{rem(20)};
    --header-height: #{rem(80)};
    --pureMesmerizingShadow: 0.08;
    --pureMesmerizingShadowCenter: 0.2;
    --pureMesmerizingShadowCenterCut: 0.2;
    --pureMesmerizingShadowHover: 0.24;
    --pageBackground: #{c(b)};
    --taxi-sc: #5f3900;
    --taxi-sc-rgb: 95, 57, 0;
    @mixin over($n1, $n2) {
        --#{$n1}: var(--#{$n2}) !important;
        --#{$n1}-hover: var(--#{$n2}-hover) !important;
        --#{$n1}-sc: var(--#{$n2}-sc) !important;
        --#{$n1}-rgb: var(--#{$n2}-rgb) !important;
        --#{$n1}-sc-rgb: var(--#{$n2}-sc-rgb) !important;
    }
    @include darkMode(true) {
        @include over(brand, brand-f);
        @include over(brand-accent, brand-accent-f);
        @include over(b, f);
        @include over(s5, s5-f);
        --pureMesmerizingShadow: 0.64;
        --pureMesmerizingShadowCenter: 0.64;
        --pureMesmerizingShadowCenterCut: 0.6;
        --pureMesmerizingShadowHover: 0.64;
        --pageBackground: var(--darkMaintheme);
    }
    &[header-appearance="compact"] {
        --header-height: #{rem(72)};
    }
    @include mobile {
        --header-height: #{rem(72)};
    }
}
html {
    color: sc(b);
    font-optical-sizing: auto;
    font-variation-settings: "wdth" 100, "slnt" 0;
    @include mobile {
        @media screen and (max-width: 374px) {
            font-size: 9px;
        }
    }
    @include desktop {
        @media screen and (max-width: 1320px) {
            font-size: 9px;
        }
        @media screen and (max-width: 1188px) {
            font-size: 8px;
        }
        @media screen and (max-width: 1056px) {
            font-size: 7px;
        }
        @media screen and (max-width: 924px) {
            font-size: 6px;
        }
        @media screen and (max-width: 792px) {
            font-size: 5px;
        }
        @media screen and (max-width: 660px) {
            font-size: 4px;
        }
    }
    #hubspot-messages-iframe-container {
        pointer-events: none;
        opacity: 0;
        transition: 400ms opacity;
    }
    &.hs-messages-widget-open {
        #hubspot-messages-iframe-container {
            opacity: 1;
            pointer-events: auto;
        }
    }
    &.hiddenscroll {
        .rc-virtual-list-scrollbar {
            opacity: 0;
            visibility: hidden;
        }
    }
    &.no-hiddenscroll {
        ::-webkit-scrollbar {
            width: map-get($scrollbar, 'size');
            height: map-get($scrollbar, 'size');
            &-button, &-corner {
                display: none;
            }
            &-thumb {
                border: map-get($scrollbar, 'space') solid transparent;
                border-radius: map-get($scrollbar, 'size');
                background: map-get($scrollbar, 'thumbBackground') content-box;
                &:hover, &:active {
                    background-color: map-get($scrollbar, 'thumbActiveBackground');
                }
            }
            &-track {
                background: transparent;
            }
        }
    }
    &.currently-dragging, &:has([style*="grabbing"]) {
        &, * {
            cursor: grabbing;
        }
    }
}
body {
    display: flex;
    margin: 0 !important;
}
#root {
    width: 100%;
    min-height: 100%;
    display: grid;
    grid-template-columns: 100%;
    grid-template-rows: auto minmax(#{$pageHeightFit}, 1fr) auto;
    grid-template-areas: 'header' 'main' 'footer';
    @include mobile {
        grid-template-rows: auto minmax(calc(var(--vih) - var(--header-height) - env(safe-area-inset-top, 0px)), 1fr) auto;
    }
    header {
        grid-area: header;
    }
    main {
        grid-area: main;
        display: flex;
        position: relative;
        > .absolute-loader {
            top: calc(50% - var(--header-height) / 2);
            [header-appearance="logo"] & {
                top: 50%;
            }
        }
    }
    footer {
        grid-area: footer;
    }
}
svg {
    @include overrideSVG(#eff1f4, var(--svg-shade-light-1, #eff1f4), var(--svg-shade-dark-1, #0e1c2e));
    @include overrideSVG(#f2f7ff, var(--svg-shade-light-2, #f2f7ff), var(--svg-shade-dark-2, #0d1723));
    @include overrideSVG(#edf5ff, var(--svg-shade-light-3, #edf5ff), var(--svg-shade-dark-3, #222530));
    @include overrideSVG(#e7edf7, var(--svg-shade-light-4, #e7edf7), var(--svg-shade-dark-4, #0f1f35));
    @include overrideSVG(#E7F1FF, var(--svg-shade-light-5, #E7F1FF), var(--svg-shade-dark-5, #0d1e32));
    @include overrideSVG(#deebff, var(--svg-shade-light-6, #deebff), var(--svg-shade-dark-6, #0e233b));
    @include overrideSVG(#d6dbee, var(--svg-shade-light-7, #d6dbee), var(--svg-shade-dark-7, #1d202a));
    @include overrideSVG(#bdd5ff, var(--svg-shade-light-8, #bdd5ff), var(--svg-shade-dark-8, #113865));
    @include overrideSVG(#8abaff, var(--svg-shade-light-9, #8abaff), var(--svg-shade-dark-9, #1255a0));
    @include overrideSVG(#ffffff, c(b), lighten($darkMode, 10));
    @include overrideSVG(#85b504, c(success), c(success));
}
.absolutely-splash {
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    position: absolute;
}
.page-layout {
    display: grid;
    grid-template-columns: 100%;
    margin: 0 0 rem(100 - $gap);
    grid-row-gap: rem($gap * 2);
    @include mobile {
        margin: 0 0 rem(30);
        grid-row-gap: rem(40);
    }
    & &, .card-holder &, .modal-content & {
        margin: 0;
    }
    &.screen-page {
        @include desktop {
            align-items: flex-start;
            grid-column-gap: per(60, $guide);
            grid-row-gap: rem(40);
            &.a-page {
                grid-template-columns: 1fr per(350, $guide);
            }
            .span-column {
                grid-column: span 2;
            }
        }
    }
    &.g-120 {
        grid-row-gap: rem(120);
    }
    &.m-100 {
        margin: rem(100) 0;
    }
}
.grid {
    width: 100%;
    display: grid;
    grid-template-columns: repeat(var(--cols, 1), var(--grid-item-width, 1fr));
    grid-row-gap: rem(30);
    grid-column-gap: rem(30);
    @include mobile {
        grid-row-gap: rem(20);
        grid-column-gap: rem(20);
    }
    &.home-top-cards {
        @include desktop {
            gap: rem(20);
            padding: 0 rem(20);
        }
    }
    &.gap-1 {
        grid-row-gap: rem(10);
        grid-column-gap: rem(10);
    }
    &.gap-1-5 {
        grid-row-gap: rem(15);
        grid-column-gap: rem(15);
    }
    &.gap-2 {
        grid-row-gap: rem(20);
        grid-column-gap: rem(20);
        .grid-margin-top {
            margin-top: rem(20);
        }
    }
    &.gap-2-4 {
        grid-row-gap: rem(24);
        grid-column-gap: rem(24);
    }
    &.gap-4 {
        grid-row-gap: rem(40);
        grid-column-gap: rem(40);
    }
    &.gap-5 {
        grid-row-gap: rem(50);
        grid-column-gap: rem(50);
    }
    @for $i from 1 to 25 {
        &.cols-#{$i} {
            --cols: #{$i};
        }
    }
    > .span {
        grid-column: span var(--cols);
        @for $i from 1 to 25 {
            &-#{$i} {
                grid-column: span #{$i};
            }
        }
    }
    &.cols-7-5 {
        grid-template-columns: 7fr 5fr;
    }
}
.v-divider {
    width: 100%;
    border-top: rem(1) solid c(s5);
}
.h-divider {
    height: 100%;
    border-left: rem(1) solid c(s5);
}
.transformReveal {
    animation: transformReveal 400ms forwards;
}
.animation-fadeIn {
    opacity: 0;
    animation: fadeIn 400ms forwards;
}
.screen-rating-holder {
    position: absolute;
    top: rem($guidePadding);
    right: rem($guidePadding);
    background: c(b);
    border-radius: rem(36);
    padding: rem(2) rem(10);
    box-shadow: $pureMesmerizingShadow;
    &.s-small {
        top: rem(10);
        right: rem(10);
    }
}
.popover-filters-grid {
    width: 100%;
    padding: rem(20);
    display: grid;
    grid-template-columns: 100%;
    grid-row-gap: rem(15);
}
.i-oes-tag {
    position: absolute;
    top: 0;
    left: 0;
    width: rem(3);
    height: rem(3);
    pointer-events: none;
    overflow: hidden;
    h1 {
        transform: scale(0);
        transform-origin: top left;
    }
}
.full-width {
    width: 100%;
    min-width: 0;
}
.visibility-hidden {
    opacity: 0;
    visibility: hidden;
}
