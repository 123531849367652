@import 'src/assets/scss/magic';
.knob-holder {
    display: flex;
    cursor: pointer;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    height: var(--height);
    min-width: var(--height);
    border-radius: var(--height);
    overflow: hidden;
    position: relative;
    transition: 300ms color, 300ms box-shadow, 400ms min-width;
    font-weight: 500;
    white-space: nowrap;
    @include mobile {
        --height: #{rem(58)};
        border-radius: rem(16);
        margin-top: rem(-6);
    }
    ::first-letter {
        text-transform: uppercase;
        user-select: none;
    }
    &:first-child {
        min-width: rem(230);
    }
    &:nth-child(2) {
        min-width: rem(260);
    }
    &:not(.np) {
        padding: 0 rem($guidePadding);
        width: 100%;
        align-items: flex-start;
    }
    &:not(.active) {
        color: sc(b, 0.48);
        font-weight: 400;
    }
    &.np {
        &.active {
            color: c(brand);
        }
    }
    @include hover('.popover-opened') {
        color: c(brand);
        box-shadow: $pureMesmerizingShadow;
        + .divider {
            opacity: 0;
        }
    }
    &:not(.popover-opened):not(.active):not(.np) {
        .knob-label {
            font-weight: 400;
            font-size: rem(14);
            transform: translateY(rem(11));
        }
        .knob-content {
            opacity: 0;
            transform: translateY(rem(6));
        }
    }
    &.highlighted {
        overflow: visible;
        color: #00A96C;
        &:before, &:after {
            content: '';
            position: absolute;
            border-radius: inherit;
        }
        &:before {
            content: '';
            position: absolute;
            top: rem(-1);
            left: rem(-1);
            bottom: rem(-1);
            right: rem(-1);
            background: linear-gradient(286deg, #97E632 4.19%, #10CBD7 112.99%);
            z-index: -2;
        }
        &:after {
            top: rem(0);
            left: rem(0);
            right: rem(0);
            bottom: rem(0);
            background: c(b);
            z-index: -1;
            @include mobile {
                border-radius: rem(15);
            }
        }
        .knob-label {
            color: #00A96C;
        }
        @include hover {
            &, & .knob-label {
                color: mix(#10CBD7, #026743);
            }
        }
        button {
            @include hover {
                color: #00A96C;
                background: rgba(#00A96C, 0.07);
            }
        }
        .white-label & {
            color: c(brand);
            &:before {
                background: c(brand);
            }
            .knob-label {
                color: c(brand);
            }
            @include hover {
                &, & .knob-label {
                    color: c(brand-hover);
                }
            }
            button {
                @include hover {
                    color: c(brand);
                    background: c(brand, 0.07);
                }
            }
        }
    }
    &:not(.popover-opened) {
        &.error {
            color: c(error);
            .knob-label {
                color: c(error, 0.7);
            }
        }
    }
}
.knob-label {
    font: 500 f(10) $f;
    color: sc(b, 0.48);
    margin: 0 0 rem(2);
    transition: 300ms color, 300ms transform, 300ms font-size, 300ms font-weight;
    display: flex;
    align-items: center;
    max-width: 100%;
    gap: rem(4);
    small {
        height: rem(18);
        margin: rem(-2) 0;
        padding: rem(8);
        display: flex;
        justify-content: center;
        align-items: center;
        background: linear-gradient(284deg, rgba(151, 230, 50, 0.20) -34.34%, rgba(16, 215, 167, 0.20) 92.86%);
        color: #43895e;
        font: 700 rem(9) / 1 $f;
        text-transform: uppercase;
        letter-spacing: rem(0.45);
        border-radius: rem(18);
        .white-label & {
            color: c(brand);
            background: c(s5);
        }
    }
}
.knob-content {
    font: rem(13) / rem(20) $f;
    font-weight: inherit;
    display: flex;
    align-items: center;
    width: 100%;
    overflow: hidden;
    transition: 400ms opacity, 400ms transform;
    > li {
        flex-shrink: 0;
        &:first-child {
            flex: auto;
        }
        + li {
            margin: 0 0 0 rem(6);
        }
        .np & {
            display: flex;
            justify-content: center;
        }
    }
    button {
        width: rem($guidePadding);
        height: rem($guidePadding);
        border-radius: 100%;
        display: flex;
        justify-content: center;
        align-items: center;
        cursor: pointer;
        color: sc(b, 0.48);
        background: sc(b, 0.05);
        transition: 400ms color, 400ms opacity, 400ms background;
        @include hover {
            color: c(brand);
            background: c(brand, 0.07);
        }
    }
}
