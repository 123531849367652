@import 'src/assets/scss/magic';
.favorites-holder {
    width: rem((190 * 2) + 20 + (20 * 2));
    padding: rem(14) rem(20);
    position: relative;
    display: flex;
    @include mobile {
        width: 100%;
    }
}
.favorites-grid {
    width: 100%;
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    grid-row-gap: rem(20);
    grid-column-gap: rem(20);
    @include mobile {
        grid-row-gap: rem(16);
        grid-column-gap: rem(16);
        grid-template-columns: 100%;
    }
}
