@import "src/assets/scss/magic";
.sp-cta-button {
    display: flex;
    align-items: center;
    justify-content: center;
    height: rem(32);
    border-radius: rem(32);
    background: c(s5);
    color: c(brand);
    border: rem(1) solid c(brand, 0.08);
    cursor: pointer;
    font: 600 f(12) $f;
    gap: rem(4);
    padding: 0 rem(12);
    flex-shrink: 0;
    transition: 400ms background-color, 400ms border-color, 400ms color;
    position: relative;
    &.hidden {
        width: 0;
        padding: 0;
        overflow: hidden;
        pointer-events: none;
        opacity: 0;
    }
    svg {
        flex-shrink: 0;
    }
    &:not(.t-hero):not(.t-warning):not(.t-danger) {
        @include hover {
            background: c(s5, 0);
            border: rem(1) solid c(brand, 1);
        }
    }
    &.t-danger {
        color: c(error);
        border-color: c(error, 0.08);
        background: c(error, 0.05);
        @include hover {
            border-color: c(error, 1);
            background: c(error, 0);
        }
    }
    &.t-hero {
        color: c(hero);
        border-color: c(hero, 0.08);
        background: c(hero, 0.05);
        cursor: default;
        @include hover {
            border-color: c(hero, 1);
            background: c(hero, 0);
        }
    }
    &.t-warning {
        color: c(warning);
        border-color: c(warning, 0.08);
        background: c(warning, 0.05);
        cursor: default;
        @include hover {
            border-color: c(warning, 1);
            background: c(warning, 0);
        }
    }
}
