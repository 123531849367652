@import "src/assets/scss/magic";
.partnership-features-head {
    h2 {
        font: 200 rem(50) / 1.2 $f;
        @include mobile {
            font-size: rem(26);
            line-height: 1.5;
        }
        s {
            font-family: $fc;
            text-decoration: none;
            color: mix(#6AD01C, #1FB992);
            display: inline-block;
            vertical-align: baseline;
            padding: rem(12) rem(12);
            margin: rem(-12);
            font-size: rem(60);
            position: relative;
            @include mobile {
                font-size: rem(36);
            }
            &:before {
                content: '';
                position: absolute;
                top: 60%;
                height: rem(2);
                left: rem(12);
                right: rem(6);
                background: linear-gradient(90deg, #6AD01C, #1FB992);
                transform: rotate(2deg);
            }
            @supports (-webkit-background-clip: text) {
                background: linear-gradient(90deg, #6AD01C, #1FB992);
                -webkit-background-clip: text;
                color: transparent;
            }
        }
        strong {
            font-weight: 800;
            color: mix(#19bfa1, #1DD77B);
            @supports (-webkit-background-clip: text) {
                background: linear-gradient(90deg, #19bfa1 0%, #1DD77B 71%);
                -webkit-background-clip: text;
                color: transparent;
            }
        }
    }
    h3 {
        width: 100%;
        max-width: rem(1000);
        text-wrap: balance;
        font: 300 rem(18) / 1.8 $f;
        color: sc(b, 0.8);
        @include mobile {
            font-size: rem(16);
        }
    }
}
.partnership-features-grid {
    width: 100%;
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    background: c(b);
    box-shadow: 0 rem(20) rem(60) rem(-30) rgba(0, 0, 0, 0.08);
    border-radius: rem(48);
    @include mobile {
        grid-template-columns: 100%;
        border-radius: rem(36);
    }
    h4 {
        font: 300 rem(24) / 1.6 $f;
        @include mobile {
            font-size: rem(20);
        }
    }
    p {
        font: 300 rem(18) / 1.8 $f;
        color: sc(b, 0.8);
        @include mobile {
            font-size: rem(16);
        }
    }
}
.partnership-feature-graphics {
    position: relative;
    width: rem(284);
    height: rem(160);
    display: flex;
    align-items: center;
    justify-content: center;
    @include mobile {
        transform: scale(0.8);
        margin: auto;
    }
    > svg {
        width: rem(80);
        height: rem(80);
    }
}
.partnership-feature-graphic-item {
    position: absolute;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: rem(100);
    background: c(b);
    gap: rem(8);
    white-space: nowrap;
    &.bubble {
        width: rem(52);
        height: rem(52);
        box-shadow: inset 0 rem(9) rem(20) rem(-16) rgba(0, 0, 0, 0.1);
        @include darkMode {
            box-shadow: inset 0 rem(9) rem(20) rem(-16) rgba(black, 0.8);
        }
        svg {
            width: rem(24);
            height: rem(24);
        }
    }
    &:not(.bubble) {
        height: rem(34);
        box-shadow: 0 0 0 rem(3) rgba(#C1C7D7, 0.15);
        padding: 0 rem(12) 0 rem(16);
        font: 700 f(11) $f;
        letter-spacing: 0.1em;
        text-transform: uppercase;
        @include darkMode {
            box-shadow: 0 0 0 rem(3) c(s5, 0.5);
        }
        svg {
            width: rem(18);
            height: rem(18);
        }
    }
    &:first-child {
        top: 0;
        left: 0;
    }
    &:nth-child(2) {
        top: 0;
        right: 0;
    }
    &:nth-child(3) {
        left: 0;
        bottom: 0;
    }
    &:nth-child(4) {
        right: 0;
        bottom: 0;
    }
}
.partnership-features-grid-child {
    position: relative;
    & + & {
        &::before {
            content: '';
            position: absolute;
            left: 0;
            width: rem(1);
            top: rem(40);
            bottom: rem(20);
            border-left: rem(1) dashed sc(b, 0.12);
            @include mobile {
                border: 0;
                top: 0;
                left: rem(24);
                right: rem(24);
                bottom: auto;
                width: auto;
                height: rem(1);
                border-top: rem(1) dashed sc(b, 0.12);
            }
        }
    }
}
.partnership-features-grid-hd {
    @include desktop {
        grid-column: span 3;
        height: rem(1);
        position: relative;
        &:before {
            content: '';
            position: absolute;
            top: 0;
            left: rem(40);
            right: rem(40);
            border-bottom: rem(1) dashed sc(b, 0.12);
        }
        ~ .partnership-features-grid-child {
            &::before {
                top: rem(20);
                bottom: rem(40);
            }
        }
    }
}
.partnership-feature-item {
    padding: rem(40);
    @include mobile {
        padding: rem(24) rem(24) rem(30);
    }
}
