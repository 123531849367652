@import "src/assets/scss/magic";
.mobile-stack-holder {
    width: 100%;
    @include mobile {
        padding: var(--guide-padding) 0;
        margin: calc(-1 * var(--guide-padding));
        width: 100vw;
        overflow-x: auto;
        overflow-y: hidden;
        display: flex;
        overscroll-behavior: none auto;
        --grid-item-width: calc(100vw - (var(--guide-padding) * 3));
        position: relative;
        z-index: 1;
        scroll-snap-type: x mandatory;
        .target-by-interests-section {
            --grid-item-width: #{rem(180)};
        }
        .modal-content & {
            width: calc(100% + (var(--guide-padding) * 2));
        }
        &:before, &:after {
            content: '';
            display: block;
            width: var(--guide-padding);
            flex-shrink: 0;
        }
        > * {
            flex-shrink: 0;
            min-width: calc(100% - (var(--guide-padding) * 2)) !important;
            width: auto !important;
        }
        &::-webkit-scrollbar {
            display: none;
        }
    }
}
