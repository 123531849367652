$mobile: 576px;

$f: 'Inter', 'Noto Sans Armenian', -apple-system, BlinkMacSystemFont, sans-serif;
$fi: 'icomoon', sans-serif;
$fc: 'Caveat', cursive;

$setColorDark: #001140;
$darkMode: #15171e;

$c: (
    hero: #4cb300,
    brand: #2b5cd1,
    rating: #F8A100,
    brand-accent: #0836A7,
    grey: #868CA3,
    base: #868CA3,
    complimentary: #089180,
    s5: #EFF5FF,
    b: #ffffff,
    pureWhite: #ffffff,
    warning: #F16D00,
    taxi: #FFC107,
    success: #4caf50,
    error: #ff004d,
    f: #15171e,
    s5-f: desaturate(#172136, 15),
    brand-f: #3d76ff,
    brand-accent-f: lighten(#3d76ff, 5),
    darkMaintheme: darken(#15171e, 4),
);

$guide: 1440 - 160;
$guidePadding: 20;
$gap: 30;

$setColorLightList: (
    #ff004d,
    #3d76ff,
);

@import "clyne-core/index";

$pageHeightFit: calc(var(--vh) - var(--header-height) - env(safe-area-inset-top, 0px));

$pureMesmerizingShadow: 0 rem(6) rem(30) rem(-10) rgba(0, 0, 0, var(--pureMesmerizingShadow));
$pureMesmerizingShadowCenter: 0 rem(1) rem(14) rem(-6) rgba(0, 0, 0, var(--pureMesmerizingShadowCenter));
$pureMesmerizingShadowHover: 0 rem(20) rem(60) rem(-20) rgba(0, 0, 0, var(--pureMesmerizingShadowHover));

$uiKitSizes: (
    small: (
        height: 32,
        padding: 14,
        fontSize: 12,
        borderRadius: 10,
        proportionalWidth: 110,
    ),
    default: (
        height: 40,
        padding: 14,
        fontSize: 14,
        borderRadius: 12,
        proportionalWidth: 110,
    ),
    medium: (
        height: 44,
        padding: 20,
        fontSize: 14,
        borderRadius: 14,
        proportionalWidth: 110,
    ),
    big: (
        height: 48,
        padding: 20,
        fontSize: 14,
        borderRadius: 16,
        proportionalWidth: 110,
    ),
    extra-big: (
        height: 52,
        padding: 20,
        fontSize: 14,
        borderRadius: 18,
        proportionalWidth: 110,
    ),
    extra-large: (
        height: 56,
        padding: 20,
        fontSize: 16,
        borderRadius: 18,
        proportionalWidth: 110,
    ),
    extra-huge: (
        height: 58,
        padding: 40,
        fontSize: 16,
        borderRadius: 18,
        proportionalWidth: 110,
    ),
);

$scrollbar: (
    'size': var(--scrollbar-size, 12px),
    'thumbSize': var(--scrollbar-thumbSize, 4px),
    'gutter': var(--scrollbar-gutter, 8px),
    'space': var(--scrollbar-space, 4px),
    'thumbBackground': sc(b, 0.2),
    'thumbActiveBackground': sc(b, 0.4),
);
