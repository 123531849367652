@import "src/assets/scss/magic";
.partnership-card-holder {
    padding: rem(92) rem(60);
    border-radius: rem(48);
    box-shadow: 0 rem(60) rem(140) rem(-60) rgba(63, 51, 111, 0.1);
    width: 100%;
    background: c(b);
    text-align: center;
    position: relative;
    overflow: hidden;
    @include mobile {
        border-radius: rem(36);
        padding: rem(36) rem(16);
    }
    &.wide {
        padding: rem(50);
    }
    > svg {
        max-width: inherit;
        position: absolute;
    }
    &.t-dark {
        background: #0A0F1D;
        color: white;
        --theme-color-1: #4CFFBF;
        --theme-color-2: #C3FFE9;
        @include darkMode {
            --theme-color-1: #00bf7b;
            --theme-color-2: var(--theme-color-1);
            background: #f3f3f5;
            color: c(b);
        }
        > svg {
            bottom: rem(-252);
            left: rem(18);
        }
    }
    &.t-pink {
        --theme-color-1: #FF00A1;
        @include darkMode {
            --theme-color-1: #fd6bc7;
        }
        > svg {
            &:first-of-type {
                left: rem(-143);
                bottom: rem(-324);
            }
            &:last-of-type {
                left: rem(166);
                bottom: rem(-230);
            }
        }
    }
    &.t-green {
        --theme-color-1: #58CD00;
        @include darkMode {
            --theme-color-1: #a3e770;
        }
        > svg {
            top: rem(-580);
            left: rem(-280);
            @include mobile {
                top: rem(-610);
                left: rem(-300);
            }
        }
        &.wide {
            > svg {
                left: rem(-167);
                top: rem(-540);
            }
        }
    }
}
.partnership-card-contents {
    position: relative;
    display: flex;
    flex-direction: column;
    align-items: center;
    text-align: center;
    min-height: 100%;
    small {
        font-size: rem(32);
        line-height: 1;
        margin: 0 0 rem(4);
        color: var(--theme-color-1);
        @include mobile {
            margin: rem(8) 0;
            font-size: rem(28);
        }
    }
    h3 {
        text-transform: uppercase;
        font: 900 rem(24) / 1.5 $f;
        margin: 0 0 rem(24);
        text-wrap: balance;
        @include mobile {
            font-size: rem(20);
            line-height: 1.3;
        }
    }
    h4 {
        font: f(18) $f;
        line-height: 1.4;
        text-wrap: balance;
        @include mobile {
            font-size: rem(16);
        }
    }
    p {
        font: f(14, 26) $f;
        opacity: 0.8;
        text-wrap: balance;
    }
    > p {
        margin: rem(12) 0 rem(34);
        @include desktop {
            min-height: rem(26 * 3);
        }
        + .partnership-card-cta-holder {
            margin: auto 0 0;
        }
    }
    .partnership-card-cta-holder {
        margin: rem(18) 0 0;
        display: flex;
        align-items: center;
        gap: rem(16);
        p {
            width: 100%;
            max-width: rem(404);
            text-align: right;
            text-wrap: balance;
            [lang="hy"] & {
                max-width: rem(550);
            }
            [lang="ka"] & {
                max-width: rem(620);
            }
        }
    }
}
