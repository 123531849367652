@import 'src/assets/scss/magic';
.notification-holder {
    width: 100%;
    display: grid;
    grid-template-columns: auto 1fr;
    padding: rem(16) rem(24);
    position: relative;
    align-items: center;
    grid-column-gap: rem(16);
    font: rem(12) / rem(20) $f;
    color: sc(b, 0.6);
    cursor: pointer;
    transition: 400ms background, 400ms opacity;
    white-space: normal;
    @include mobile {
        padding: rem(18) rem(20);
        grid-column-gap: rem(12);
    }
    > li {
        display: grid;
        grid-template-columns: 100%;
        grid-row-gap: rem(3);
    }
    &.disabled {
        pointer-events: none;
    }
    @include hover {
        .notification-head {
            h6 {
                color: c(brand);
            }
        }
    }
    &.a-compact {
        @include hover {
            background: c(s5, 0.6);
            .notification-ii-holder {
                background-color: c(b);
            }
        }
    }
    &.a-expanded {
        padding: rem(20);
        .notification-ii-holder {
            border-radius: 100%;
        }
        a {
            &:before {
                @include startIndicator;
            }
            &.active {
                &:before {
                    transform: translate3d(0, 0, 0);
                }
            }
        }
    }
    .placeholder {
        opacity: 0.5;
    }
}
.notification-ii-holder {
    width: rem(60);
    height: rem(60);
    border-radius: rem(16);
    background: c(brand, 0.05) no-repeat 50% 50% / cover;
    display: flex;
    justify-content: center;
    align-items: center;
    position: relative;
    color: c(brand);
    transition: 400ms background;
    @include mobile {
        width: rem(58);
        height: rem(58);
    }
    &.i-image {
        border-radius: 100%;
    }
    &.i-icon-a-cross-circle, &.i-icon-a-cross {
        @include over(brand, error);
    }
    &.i-icon-a-trash {
        @include over(brand, error);
    }
    &.i-icon-a-megaphone, &.i-icon-a-image {
        @include over(brand, success);
    }
    &.i-icon-a-check-circle, &.i-icon-a-check {
        @include over(brand, success);
    }
}
.inline-icon-holder {
    width: rem(26);
    height: rem(26);
    border-radius: rem(9);
    position: absolute;
    bottom: rem(-6);
    right: rem(-6);
    background: c(b);
    color: c(brand);
    display: flex;
    justify-content: center;
    align-items: center;
    .icon-a-cross, .icon-a-check {
        -webkit-text-stroke: rem(0.3);
    }
}
.notification-badge {
    position: absolute;
    top: rem(0);
    right: rem(0);
    width: rem(10);
    height: rem(10);
    background: c(brand);
    border-radius: 100%;
    transition: 300ms transform, 300ms box-shadow;
    box-shadow: 0 0 0 rem(2) c(b);
    &:not(.active) {
        box-shadow: 0 0 0 0 c(b);
        transform: scale(0);
    }
}
.notification-head {
    width: 100%;
    display: grid;
    grid-column-gap: rem(6);
    grid-template-columns: 1fr auto;
    align-items: center;
    h6 {
        font: f(14, 20) $f;
        color: sc(b);
        transition: 400ms color;
        &.strong {
            font-weight: 500;
        }
        .active ~ & {
            color: c(brand);
        }
    }
    time {
        font: 500 f(10) $f;
        color: sc(b, 0.41);
    }
}
