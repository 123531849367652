@import 'src/assets/scss/magic';
.favorite-holder {
    width: rem(30);
    height: rem(30);
    position: relative;
    background: c(b);
    border-radius: 100%;
    cursor: pointer;
    box-shadow: $pureMesmerizingShadowCenter;
    transition: 400ms transform;
    &.s-big {
        width: rem(48);
        height: rem(48);
    }
    &.inline {
        width: rem(18);
        height: rem(18);
        box-shadow: none;
    }
    > li {
        transition: 400ms transform, 400ms color, 400ms opacity;
        display: flex;
        justify-content: center;
        align-items: center;
        &:first-child {
            color: sc(b, 0.41);
            @include hover {
                color: sc(b, 0.48);
            }
        }
        &:nth-child(2) {
            color: #cb2516;
            @include hover {
                color: set-lightness(#cb2516, 5);
            }
        }
    }
    &.active > li:first-child, &:not(.active) > li:nth-child(2) {
        transform: scale(0);
        opacity: 0;
    }
    @include hover {
        transform: scale(1.1);
    }
}
