@import "src/assets/scss/magic";
.rfm-marquee-container {
    $clip: 5;
    width: calc(100% + #{rem($clip * 2)});
    margin: 0 rem(-2 * $clip);
    padding: 0 rem($clip * 2);
    mask-image: linear-gradient(90deg, rgba(black ,0), #000 rem($clip), #000 calc(100% - #{rem($clip * 3)}), rgba(black ,0));
    .rfm-marquee {
        padding: 0 rem(24) 0 0;
    }
    &.fits {
        mask-image: none;
        .rfm-marquee {
            padding: 0;
        }
    }
}
