@import "src/assets/scss/magic";
.blur {
    width: var(--width);
    height: var(--height);
    transform: translate3d(var(--x), -50%, 0);
    position: absolute;
    top: 50%;
    opacity: 0.2;
    padding-top: 120%;
    &:before {
        content: "";
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        background: no-repeat 50% 50% / contain;
        animation: floating-element infinite 10s var(--delay, 0ms);
        transform: translate3d(0, 0, 0) scale(1) rotate(0.5turn);
        opacity: 0.5;
    }
    &.blur-1 {
        --x: -25%;
        --width: #{per(528, 508)};
        left: 0;
        &:before {
            background-image: url("./media/blur-1.png");
        }
    }
    &.blur-2 {
        --x: 25%;
        --width: #{per(640, 508)};
        --delay: -3s;
        right: 0;
        opacity: 0.3;
        &:before {
            background-image: url("./media/blur-2.png");
        }
    }
}
@keyframes floating-element {
    from, to {
        transform: translate3d(0, 0, 0) scale(1) rotate(0.5turn);
        opacity: 0.5;
    }
    50% {
        transform: translate3d(rem(4), rem(-40), 0) scale(.8) rotate(0deg);
        opacity: 1;
    }
}
