@import 'src/assets/scss/magic';
header {
    transition: 400ms box-shadow, 400ms padding;
    position: relative;
    z-index: 100;
    padding: env(safe-area-inset-top, 0px) 0 0 0;
    &.a-default {
        position: sticky;
        top: 0;
        color: var(--header-color, #{sc(b)});
        background: var(--header-background, #{c(b)});
        &:not(.scrolled) {
            .header-wizard-c, .header-c {
                &:before {
                    opacity: 1;
                    visibility: visible;
                    @include darkMode {
                        opacity: 0;
                        visibility: hidden;
                    }
                }
            }
        }
    }
    &.a-compact {
        background: c(b);
    }
    &.a-logo {
        height: rem(0);
    }
    .divider {
        height: rem(50);
        width: rem(1);
        background: var(--header-accent, #{c(s5)});
    }
    > .guide {
        @include mobile {
            padding: 0 rem(15);
        }
    }
}
.header-wizard-c, .header-c {
    position: relative;
    &:before {
        content: "";
        position: absolute;
        bottom: 0;
        left: 0;
        right: 0;
        height: rem(1);
        pointer-events: none;
        background: c(s5);
        transition: 400ms opacity, 400ms visibility;
        opacity: 0;
        visibility: hidden;
        @include mobile {
            left: rem(5);
            right: rem(5);
        }
    }
}
.header-c {
    width: 100%;
    display: flex;
    align-items: center;
    height: var(--header-height);
    transition: height 400ms;
    will-change: height;
    @include mobile {
        display: grid;
        grid-template-columns: 1fr auto 1fr;
        align-items: center;
    }
    > li {
        display: flex;
        align-items: center;
        flex-shrink: 0;
        > * {
            opacity: 0;
            animation: fadeIn forwards 400ms;
        }
        @include mobile {
            justify-content: flex-start;
            transition: transform 400ms;
            .mobile-navigation-opened & {
                &:not(:first-child) {
                    z-index: 12;
                    transform: translateY(rem(10));
                }
            }
        }
        > * {
            + * {
                &:not(.header-uneven-gap) {
                    @include desktop {
                        margin: 0 0 0 rem(30);
                    }
                }
            }
        }
        &:first-child {
            flex: auto;
            > * {
                + * {
                    &:not(.header-uneven-gap) {
                        @include desktop {
                            margin: 0 0 0 rem(15);
                        }
                    }
                }
            }
        }
        &:last-child {
            @include mobile {
                justify-content: flex-end;
            }
        }
    }
}
nav {
    @include desktop {
        gap: rem(40);
        display: flex;
        white-space: nowrap;
        &.reduce-gap {
            gap: rem(36);
        }
    }
    @include mobile {
        margin: auto;
        width: 100%;
        display: grid;
        grid-template-columns: 100%;
        grid-row-gap: rem(15);
    }
}
@mixin routeActive {
    @include desktop {
        box-shadow: inset 0 rem(-2) 0 var(--header-brand, #{c(brand)});
    }
    @include mobile {
        color: c(brand);
    }
}
.route {
    position: relative;
    .icon-a-loader {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
    }
    &:not(.loading), .hs-messages-widget-open & {
        .icon-a-loader {
            display: none;
        }
    }
    &.loading {
        span {
            opacity: 0;
            .hs-messages-widget-open & {
                opacity: 1;
            }
        }
    }
    @include desktop {
        display: flex;
        align-items: center;
        font-weight: 500;
        box-shadow: inset 0 0 0 c(brand);
        transition: box-shadow 250ms, height 400ms, color 400ms;
        cursor: pointer;
        text-transform: capitalize;
        gap: rem(12);
        @include desktop {
            height: var(--header-height);
        }
        @include mobile {
            font: 500 rem(16) / rem(20) $f;
        }
        @include hover-active {
            @include routeActive;
        }
    }
    @include mobile {
        width: 100%;
        display: grid;
        height: rem(40);
        grid-template-columns: rem(22) 1fr auto;
        align-items: center;
        grid-column-gap: rem(12);
        font: rem(16) / rem(24) $f;
        .route-icon {
            opacity: 0.9;
        }
        .icon-a-chevron-right {
            opacity: 0.5;
        }
        span {
            &:first-child {
                grid-column: 2 / 2;
                font-size: rem(14);
                opacity: 0.8;
            }
        }
        &.active {
            font-weight: 600;
            .route-icon {
                opacity: 1;
            }
        }
    }
}
.sub-menu-holder {
    @include desktop {
        position: relative;
        @include mobile {
            width: 100%;
            display: flex;
            flex-direction: column;
            align-items: center;
            justify-content: center;
        }
        i {
            opacity: 0.5;
            -webkit-text-stroke: rem(0.5);
            transition: 400ms opacity;
            &:before {
                display: block;
                transition: 400ms transform;
            }
        }
        &:hover {
            .route {
                @include routeActive;
            }
            i {
                opacity: 1;
                &:before {
                    transform: rotate(-180deg);
                }
            }
            .route-submenu-positioner {
                clip-path: inset(0);
                opacity: 1;
                transition-delay: 100ms;
                visibility: visible;
                filter: drop-shadow(0 rem(3) rem(6) rgba(black, 0.1));
            }
        }
    }
    @include mobile {
        &.opened {
            .icon-a-chevron-right {
                transform: rotate(90deg);
            }
        }
    }
}
.route-submenu-positioner {
    @include desktop {
        position: absolute;
        top: 100%;
        left: 50%;
        opacity: 0;
        visibility: hidden;
        white-space: nowrap;
        clip-path: inset(0 0 100% 0);
        padding: rem(6) rem(12) rem(12);
        transform: translate3d(-50%, 0, 0);
        filter: drop-shadow(0 rem(3) rem(6) rgba(black, 0));
        transition: 400ms opacity, 400ms clip-path, 400ms visibility, 400ms filter;
        ul {
            background: c(b);
            color: sc(b);
            border-radius: rem(10);
            padding: rem(12) 0;
            li {
                a {
                    display: block;
                    padding: 0 rem(24);
                    transition: 400ms color, 400ms opacity;
                    font: 450 rem(14) / rem(50) $f;
                    @include hover-active {
                        color: c(brand);
                    }
                }
            }
        }
    }
    @include mobile {
        overflow: hidden;
        opacity: 0;
        max-height: 0;
        transition: 400ms opacity, 400ms max-height;
        .opened & {
            opacity: 1;
            max-height: calc(#{rem(45)} * var(--items-count));
        }
        ul {
            margin: rem(5) 0 0;
            display: grid;
            grid-template-columns: 100%;
            grid-row-gap: rem(5);
        }
        .active {
            span {
                opacity: 1;
                font-weight: 600;
            }
        }
    }
}
.h-location-s-holder {
    max-width: rem(260);
    width: 100%;
}
.header-uneven-gap {
    margin: 0 0 0 rem(20);
    display: flex;
    align-items: center;
    gap: rem(15);
}
.header-cta {
    display: flex;
    align-items: center;
    > * {
        + * {
            margin: 0 0 0 rem(10);
        }
    }
    @include mobile {
        .button {
            &.c-grayscale {
                background: rgba(white, 0.1);
                color: white;
                @include hover {
                    background: rgba(white, 0.15);
                }
            }
        }
    }
}
.header-wizard-c {
    height: var(--header-height);
    width: 100%;
    display: grid;
    grid-template-columns: auto 1fr auto auto;
    align-items: center;
    grid-column-gap: rem(20);
    @include mobile {
        grid-template-columns: 1fr auto 1fr;
    }
    > li {
        display: flex;
        align-items: center;
        justify-content: center;
        position: relative;
        @include mobile {
            &:last-child {
                justify-content: flex-end;
            }
        }
        > h2 {
            font: 600 f(16) $f;
            @include mobile {
                font-size: rem(14);
            }
        }
    }
}
.mobile-navigation-toggle {
    $gap: 4;
    $size: 48;
    width: rem($size);
    height: rem($size);
    position: relative;
    z-index: 20;
    cursor: pointer;
    &:before, &:after {
        content: '';
        display: block;
        height: rem(1);
        background: var(--header-color, #{sc(b)});
        width: 50%;
        position: absolute;
        top: 50%;
        left: 50%;
        border-radius: rem(2);
        transition: 400ms transform, 400ms background-color;
    }
    &:before {
        transform: translate3d(-50%, calc(-50% - #{rem($gap)}), 0);
    }
    &:after {
        transform: translate3d(-50%, calc(-50% + #{rem($gap)}), 0);
    }
    &.active {
        &:before, &:after {
            background: white;
        }
        &:before {
            transform: translate3d(-50%, -50%, 0) rotate(-45deg);
        }
        &:after {
            transform: translate3d(-50%, -50%, 0) rotate(45deg);
        }
    }
}
.mobile-navigation-holder {
    position: fixed;
    bottom: 0;
    left: 0;
    width: 100%;
    z-index: 10;
    max-width: 100vw;
    background: rgba(#01040B, 0.92);
    color: white;
    backdrop-filter: blur(rem(30));
    height: calc(var(--vh) + #{rem(500)});
    transition: 600ms transform, 300ms padding, 300ms height, 100ms opacity;
    padding: calc(#{rem(92 + 500)} + env(safe-area-inset-top, 0px)) rem(30) calc(rem(30) + env(safe-area-inset-bottom, 0));
    display: grid;
    grid-template-rows: auto 1fr auto auto;
    overflow-x: hidden;
    overflow-y: auto;
    grid-row-gap: rem(20);
    box-shadow: 0 rem(-300) 0 0 rgba(#01040B, 0.92);
    &:not(.active) {
        transform: translate3d(0, -100%, 0);
        opacity: 0;
        transition: 600ms transform, 300ms padding, 300ms height, 100ms opacity 500ms;
    }
    > li {
        width: 100%;
        display: flex;
        flex-direction: column;
        &.mn-divider {
            height: rem(1);
            background: rgba(white, 0.1);
        }
    }
}
.mn-footer-holder {
    width: 100%;
    background: white;
    border-radius: rem(100);
    @include over(b, pureWhite);
    position: relative;
    padding: rem(10);
    display: grid;
    grid-template-columns: 1fr auto;
    > li {
        display: flex;
        align-items: center;
        justify-content: space-evenly;
        &:first-child {
            > * {
                &:first-child:nth-last-child(2) {
                    &, ~ * {
                        margin-right: auto;
                    }
                }
            }
        }
    }
}
.header-signed-in-actions {
    display: flex;
    align-items: center;
    gap: rem(20);
}
.header-secondary-divider {
    width: rem(2);
    border-radius: rem(2);
    height: rem(30);
    background: c(s5);
}
.language-switcher-flag-holder {
    cursor: pointer;
    width: rem(36);
    height: rem(36);
    display: flex;
    align-items: center;
    justify-content: center;
    > * {
        transition: 400ms transform, 400ms filter;
    }
    &.popover-opened {
        > * {
            transform: scale(0.9);
        }
    }
    @include hover('.popover-opened') {
        > * {
            filter: saturate(1.2);
        }
    }
}

