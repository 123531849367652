@import "src/assets/scss/magic";
.ant-input {
    padding-top: 0;
    padding-bottom: 0;
}
.ant-input-affix-wrapper, .ant-picker {
    padding-top: 0;
    padding-bottom: 0;
}

@each $size, $props in $uiKitSizes {
    $height: map-get($props, height);
    $padding: map-get($props, padding);
    $fontSize: map-get($props, 'fontSize');
    $borderRadius: map-get($props, 'borderRadius');
    .#{$size} {
        &.ant-input {
            height: rem($height);
            border-radius: rem($borderRadius);
            padding-inline-end: rem($padding);
            padding-inline-start: rem($padding);
            font-size: rem($fontSize);
        }
        &.ant-input-affix-wrapper {
            border-radius: rem($borderRadius);
            input {
                height: rem($height - 2);
                font-size: rem($fontSize);
            }
        }
        &.ant-select:not(.ant-select-borderless) {
            height: rem($height);
            .ant-select-arrow {
                font: rem(16) / 1 'icomoon', serif;
                .anticon-down {
                    &:after {
                        content: '\ea72';
                    }
                }
                .anticon-search {
                    &:after {
                        content: '\ea54';
                    }
                }
                svg {
                    display: none;
                }
            }
            .ant-select-selector {
                border-radius: rem($borderRadius);
                &:not(:has(.ant-select-prefix)) {
                    padding-inline-end: rem($padding);
                    padding-inline-start: rem($padding);
                }
                input {
                    font-size: rem($fontSize);
                }
                .ant-select-selection-placeholder {
                    font-size: rem($fontSize);
                }
            }
            &.ant-select-multiple .ant-select-selection-overflow-item-suffix {
                height: rem($height - 8);
            }
        }
        &.ant-input-number, &.ant-input-number-affix-wrapper {
            border-radius: rem($borderRadius);
            overflow: hidden;
            .anticon {
                font: rem(12) / 1 'icomoon', serif !important;
                svg {
                    display: none;
                }
                &.anticon-up {
                    &:after {
                        content: '\ea32';
                    }
                }
                &.anticon-down {
                    &:after {
                        content: '\ea72';
                    }
                }
            }
            &:not(:has(.ant-input-number-prefix)) {
                padding-inline-end: rem($padding - 11);
                padding-inline-start: rem($padding - 11);
            }
            .ant-input-number-input {
                height: rem($height - 2);
                font-size: rem($fontSize);
            }
            .ant-input-number-handler-wrap, .ant-input-number-handler-up {
                border-start-end-radius: rem($borderRadius);
            }
            .ant-input-number-handler-wrap, .ant-input-number-handler-down {
                border-end-end-radius: rem($borderRadius);
            }
        }
        &.ant-picker {
            border-radius: rem($borderRadius);
            &:not(:has(.ant-picker-prefix)) {
                padding-inline-end: rem($padding);
                padding-inline-start: rem($padding);
            }
            .anticon {
                &.anticon-calendar {
                    font: rem(16) / 1 'icomoon', serif;
                    &:after {
                        content: '\ea89';
                    }
                    svg {
                        display: none;
                    }
                }
            }
            .ant-picker-prefix {
                flex-shrink: 0;
            }
            .ant-picker-input {
                > input {
                    height: rem($height - 2);
                    font-size: rem($fontSize);
                }
            }
        }
        &.rounded {
            &.ant-input, &.ant-input-affix-wrapper, &.ant-select .ant-select-selector, &.ant-input-number, &.ant-input-number-affix-wrapper, &.ant-picker {
                border-radius: rem(100);
            }
        }
    }
}
.ant-select-dropdown {
    border-radius: rem(24);
    box-shadow: 0 rem(20) rem(30) rgba(0, 0, 0, 0.1);
    padding: rem(6) 0;
    background: c(b);
    .ant-select-item {
        padding: 0;
        background: none;
    }
}
.ant-steps {
    .ant-steps-icon {
        vertical-align: baseline;
    }
    .ant-steps-item-process {
        > .ant-steps-item-container {
            > .ant-steps-item-content {
                > .ant-steps-item-title {
                    &:after {
                        background: c(s5);
                    }
                }
            }
        }
    }
    &.ant-steps-small {
        .ant-steps-item-title {
            padding-inline-end: rem(12);
            font-size: rem(14);
            line-height: rem(24);
            &:after {
                top: rem(12);
            }
        }
        .ant-steps-item-icon {
            width: rem(24);
            height: rem(24);
            margin-inline: 0 rem(8);
            font-size: rem(12);
            line-height: rem(24);
            border-radius: rem(24);
        }
    }
    .ant-steps-item-wait {
        .ant-steps-item-icon {
            background-color: sc(b, 0.06);
        }
    }
}
