@import 'src/assets/scss/magic';
.tooltip-holder {
    --gap: #{rem(10)};
    z-index: 200;
    transform: translate3d(0, rem(-10), 0);
    opacity: 0;
    animation: 500ms transformReveal forwards;
    text-align: center;
    white-space: nowrap;
    @media print {
        display: none !important;
    }
    &.content-screen-online {
        @include mobile {
            overflow: hidden;
            left: auto !important;
            right: rem(10) !important;
            max-width: calc(100vw - #{rem(20)});
        }
    }
    &[data-popper-placement*="top"] {
        padding-bottom: var(--gap);
    }
    &[data-popper-placement*="left"] {
        padding-right: var(--gap);
    }
    &[data-popper-placement*="right"] {
        padding-left: var(--gap);
    }
    &[data-popper-placement*="bottom"] {
        padding-top: var(--gap);
    }
}
.tooltip, .str-chat__tooltip, .recharts-default-tooltip {
    background: c(b) !important;
    font: 500 f(11, 19) $f !important;
    padding: rem(8) rem(20) !important;
    border-radius: rem(18) !important;
    box-shadow: $pureMesmerizingShadowCenter !important;
    border: 0 !important;
    .recharts-tooltip-item-list {
        display: grid;
        padding: 0 !important;
        grid-template-columns: 100%;
        font-size: rem(10);
        > li {
            padding: 0 !important;
            .recharts-tooltip-item-name {
                color: sc(b, 0.8);
            }
            .recharts-tooltip-item-value {
                font-weight: 700;
            }
        }
    }
}
