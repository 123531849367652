@import 'src/assets/scss/magic';
.collapse-holder {
    width: 100%;
    .collapse-css-transition {
        > * {
            &:first-child {
                margin: rem($guidePadding) 0 0;
            }
        }
    }
}
.collapse-label-holder {
    font: 600 f(16) $f;
    cursor: pointer;
    display: flex;
    align-items: center;
    transition: 400ms color;
    @include mobile {
        min-height: rem(36);
        font-size: rem(14);
        --ui-kit-small-height: #{rem(28)};
        --ui-kit-small-proportionalWidth: var(--ui-kit-small-height);
    }
    @include hover {
        color: c(brand);
    }
    > li {
        flex-shrink: 0;
        &:first-child {
            flex: auto;
        }
    }
}
.collapse-css-transition-holder {
    transform: translate3d(0, 0, 0);
    width: 100%;
}
.collapse-css-transition {
    transition: height 400ms, opacity 400ms, width 400ms;
    display: flex;
    flex-direction: column;
    align-items: stretch;
    will-change: height;
    width: 100%;
    opacity: 1 !important;
    visibility: visible !important;
    &.--c-collapsing, &.--c-collapsed {
        opacity: 0 !important;
    }
    &.--c-expanded {
        overflow: visible !important;
    }
}
