@import 'src/assets/scss/magic';
.guide {
    display: flex;
    flex-direction: column;
    padding: 0 var(--guide-padding);
    width: 100%;
    margin: 0 auto;
    max-width: calc(#{rem($guide)} + (var(--guide-padding) * 2));
    transition: 500ms max-width;
    will-change: max-width;
    &.middle {
        margin: auto;
    }
    &.padding {
        padding: rem($gap) var(--guide-padding);
        @include mobile {
            padding: var(--guide-padding);
        }
    }
    &.rubber {
        max-width: 100%;
    }
    &.fit {
        flex: auto;
        height: 100%;
    }
}
