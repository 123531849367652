@import 'src/assets/scss/magic';
.main-loader-holder {
    position: fixed;
    inset: 0;
    z-index: 100000;
    display: flex;
    pointer-events: none;
    > li {
        $transition: 300ms;
        margin: auto;
        transition: opacity $transition, visibility $transition, transform $transition;
        &:not(.active) {
            opacity: 0;
            visibility: hidden;
            transform: scale(0.8);
        }
    }
}
.loader-spinner {
    $size: 80;
    $border-size: 1;
    $color: c(brand);
    $duration: 0.4s;
    $align: middle;
    width: rem($size);
    height: rem($size);
    border: rem($border-size) solid $color;
    border-right-color: transparent;
    border-radius: 50%;
    position: relative;
    animation: loader-rotate $duration linear infinite;
    top: calc(50% - #{rem(40)});
    margin: 0 auto;
    &::after {
        content: '';
        width: rem($border-size);
        height: rem($border-size);
        background: $color;
        border-radius: 50%;
        position: absolute;
        top: rem(circle-position($size, $border-size, y));
        left: rem(circle-position($size, $border-size, x));
    }
    @keyframes loader-rotate {
        0% {
            transform: rotate(0);
        }
        100% {
            transform: rotate(360deg);
        }
    }
    &.middle {
        top: 0;
        margin: auto;
    }
    &.absolute {
        top: 0;
        margin: 0;
    }
}
.absolute-loader {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate3d(-50%, -50%, 0);
    .virtual-list-holder &,
    .image-holder &,
    .a-compressed &,
    .a-compact &,
    .a-stack &,
    .a-zip & {
        transform: translate3d(-50%, -50%, 0) scale(0.7);
    }
}
.loader-bar-holder {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: rem(1);
    background: c(brand);
    z-index: 100;
    background: linear-gradient(to right, c(brand) 8%, c(b) 18%, c(brand) 33%);
    transition: 400ms opacity, 400ms visibility;
    transition-delay: 1s;
    &.active {
        animation: 3s loader-bar infinite linear forwards;
    }
    &:not(.active) {
        opacity: 0;
        visibility: hidden;
        transition-delay: 0ms;
    }
    @keyframes loader-bar {
        0% {
            background-position: 0 0;
        }
        100% {
            background-position: 1000em 0;
        }
    }
}
.loader-placement-holder {
    width: 100%;
    display: flex;
    min-width: rem(280);
    min-height: rem(306);
    &.pt-auth {
        min-height: rem(440);
        @include mobile {
            min-height: rem(416);
        }
    }
    .po-header & {
        display: none;
    }
}
