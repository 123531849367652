@import 'src/assets/scss/magic';
.popover-holder {
    z-index: 200;
    .modal-focus-on ~ & {
        z-index: 1000;
    }
    @include desktop {
        filter: drop-shadow(0 rem(20) rem(30) rgba(black, 0.1));
        &:has(.po-content:only-child:empty) {
            display: none;
        }
    }
    @include mobile {
        position: fixed;
        width: 100%;
        bottom: 0;
        max-width: 100vw;
        height: calc(var(--vh) + #{rem(500)} - env(safe-area-inset-top, 0px));
        transition: 300ms height, 300ms padding;
        padding: calc(#{rem(10 + 500)} + env(safe-area-inset-top, 0px)) rem(10) calc(#{rem(10)} + env(safe-area-inset-bottom, 0));
        display: flex;
        align-items: flex-end;
        overflow: hidden;
        &.animate-pointer-events {
            &, .popover-backdrop {
                pointer-events: none;
                animation: 200ms animate-pointer-events forwards 200ms;
                @keyframes animate-pointer-events {
                    to {
                        pointer-events: auto;
                    }
                }
            }
        }
    }
}
.popover-backdrop {
    backdrop-filter: blur(rem(10))
}
.popover-c {
    width: 100%;
    display: flex;
    flex-direction: column;
    max-height: var(--vh);
    overflow-y: auto;
    overflow-x: hidden;
    background: c(b);
    border-radius: rem(16);
    position: relative;
    pointer-events: auto;
    @include desktop {
        max-height: calc(var(--vh) - var(--rendered-top) - #{rem(4)});
    }
    @include mobile {
        min-height: rem(30);
        max-height: calc(var(--vih) - #{rem(100)} - env(safe-area-inset-top, 0px));
        transition: 500ms max-height;
        box-shadow: 0 rem(6) rem(12) rgba(black, 0.1);
        border-radius: rem(24);
    }
    .sticky & {
        @include desktop {
            max-height: calc(var(--vh) - var(--top) - #{rem(4)});
        }
    }
    > li {
        flex-shrink: 0;
        width: 100%;
        overflow: hidden;
        &.po-content {
            flex: auto;
            border-radius: rem(6);
            min-height: rem(1);
            overscroll-behavior: none none;
            padding: rem(6) 0;
            position: relative;
            @include mobile {
                flex-shrink: 0;
                .focused & {
                    .no-data-holder {
                        width: 100%;
                        position: absolute;
                        top: 50%;
                        left: 0;
                        transform: translate(0, -50%);
                    }
                }
            }
            &.padding {
                padding: rem(30) rem(36);
                @include mobile {
                    padding: rem(24);
                }
            }
            &.clipped {
                overflow-x: hidden;
                overflow-y: auto;
                max-height: calc((var(--maxItems) * var(--itemHeight)));
            }
        }
        &:not(.po-content) {
            background: c(b);
            position: sticky;
            z-index: 10;
        }
        &.po-header {
            top: 0;
            border-radius: rem(6) rem(6) 0 0;
            &:empty {
                display: none;
            }
            &.border {
                box-shadow: inset 0 rem(-1) 0 c(s5);
                .po-header-module {
                    padding-bottom: rem(20);
                }
            }
        }
        &.po-footer {
            border-top: rem(1) solid c(s5);
            bottom: 0;
            border-radius: 0 0 rem(6) rem(6);
        }
    }
    .divider {
        height: rem(1);
        background: c(s5);
    }
}
.po-header-module {
    width: 100%;
    display: grid;
    grid-template-columns: 1fr auto;
    align-items: center;
    padding: rem(26) rem(24) 0;
    grid-column-gap: rem($guidePadding);
    color: sc(b, 0.41);
    font: f(12) $f;
    overflow: hidden;
    grid-row-gap: rem(10);
    @include mobile {
        grid-row-gap: rem(20);
    }
    > li {
        display: grid;
        grid-template-columns: 100%;
        grid-row-gap: rem(6);
        overflow: hidden;
        > .po-h-m-title {
            font: 600 f(18) $f;
            color: sc(b);
            @include mobile {
                font-size: rem(16);
                color: c(brand);
            }
        }
        &.span-children {
            grid-column: span 2;
        }
    }
}
.po-m-f-cta-holder {
    padding: rem(20) rem(24) rem(26);
    width: 100%;
    display: flex;
    justify-content: center;
    gap: rem(10);
}
