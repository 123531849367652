@import 'src/assets/scss/magic';
.clip-holder {
    position: relative;
    max-width: 100%;
    .clip-value-holder {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
    }
    &:not(.active) > li:first-child,
    &.active > li:last-child {
        opacity: 0;
    }
}
