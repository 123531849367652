@import "src/assets/scss/magic";
.blur-hash-c-holder {
    opacity: 0;
    visibility: hidden;
    transition: 400ms opacity, 400ms visibility;
    transition-delay: 1s;
    pointer-events: none;
    &.active {
        opacity: 1;
        visibility: visible;
        transition-delay: 0ms;
    }
    &, * {
        overflow: hidden;
        border-radius: inherit;
        top: 0 !important;
        left: 0 !important;
        width: 100% !important;
        height: 100% !important;
        display: block !important;
        position: absolute !important;
    }
    > div {
        opacity: 0;
        animation: fadeIn 250ms forwards;
    }
    canvas {
        filter: saturate(2);
    }
}
